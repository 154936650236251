<div class="date-range-row"  #hideDiv>
  <h2>Custom date range</h2>
  <div class="start-date">
    <div class="input-field-group">
      <input type="text" [value]="selectedStartDate | date: 'dd/MM/yyyy'" [readOnly]="!selectedStartDate" required />
      <label> Start date </label>
    </div>
    <mat-calendar  [maxDate]="maxDate"
    [minDate]="minDate" [selected]="selectedStartDate" (selectedChange)="onSelect($event)"></mat-calendar>
  </div>
  <div class="end-date">
    <div class="input-field-group">
      <input type="text" [value]="selectedEndDate | date: 'dd/MM/yyyy'" [readOnly]="!selectedEndDate" required />
      <label> End date </label>
    </div>
    <mat-calendar  [maxDate]="maxDate"
    [minDate]="minDate" [selected]="selectedEndDate" (selectedChange)="onSelect2($event)"></mat-calendar>
  </div>
  <div class="action-buttons">
    <a class="btn btn-block btn-outline-secondary" (click)="closecustom()"> Cancel </a>
    <a class="btn primary-btn btn-submit " (click)="apply()"> Apply </a>
  </div>
</div>
