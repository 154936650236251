import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { FormatForExcelNearmissPipe } from 'src/app/_pipe/format-for-excel-nearmiss.pipe';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { SharedService } from 'src/app/shared/shared.service';
import * as XLSX from 'xlsx';
interface Header {
  main?: string;
  sub?: string[];
}

@Component({
  selector: 'app-absentism-attrition',
  templateUrl: './absentism-attrition.component.html',
  styleUrls: ['./absentism-attrition.component.css']
})
export class AbsentismAttritionComponent {
  minDate: any
  maxDate: any;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  pageEvent: any;
  totalRecords!: number;
  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;
  loader: boolean = false;
  warehouseList: any[] = [];
  customerList: any[] = [];
  selectedWarehouse: any;
  selectedCustomer: any;
  userRole: any;
  warehouseId: any;
  userDetails: any;
  absentismData: any = [];
  SelectedStartDate: any;
  SelectedEndDate: any;
  absentismHeader: any
  absentismList: any = [];
  isChecked: boolean = true;
  attritionHeader: any;
  attritionList: any = [];
  processedAbsentismList: any = [];
  confirmationDialogDisplay: boolean = false;
  progress: number = 0;
  showExcel = false;
  showReason = false;
  data: any[] = [];
  totalCalls: any;
  chunkSize: any;

  theme = 'dark'
  constructor(private fb: FormBuilder, public dialog: MatDialog, private auth: AuthService, private datePipe: DatePipe, private messageService: MessageService,
    private reportService: ReportsService, private sharedService: SharedService, private formatNearmess: FormatForExcelNearmissPipe) {

  }

  ngOnInit(): void {
    this.absentismHeader = localStorage.getItem('absentismList');
    this.absentismList = JSON.parse(this.absentismHeader);

    this.attritionHeader = localStorage.getItem('attritionList');
    this.attritionList = JSON.parse(this.attritionHeader);

    console.log(this.processedAbsentismList);
    const obj = this.setFilter();
    this.getAbsentismDetail(obj, this.pageIndex, this.pageSize);
    const warehouselist = JSON.parse(localStorage.getItem('warehouseList') || '[]');

    const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
    this.customerList = customerList ? customerList : [];

    this.warehouseList = warehouselist ? warehouselist : [];
    this.selectedWarehouse = []
    if (this.userRole === 'ROLE_USER') {
      this.warehouseId = this.userDetails.userWarehouseMapping[0].warehouseId
      this.warehouseList.filter((el: any) => {
        if (el.id === this.warehouseId) {
          this.selectedWarehouse.push(el.plantName)
        }
      })
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }
    else {
      this.selectedWarehouse = []
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }
  }

  filterForm = this.fb.group({
    rangeDates: new FormControl(''),
    warehouse: new FormControl(),
    customer: new FormControl()
  });

  tableEditForm = this.fb.group({
    totalManPowerPlanned: new FormControl(''),
    adhocManpower: new FormControl(''),
    shiftHours: new FormControl(''),
    manPowerOnUninformedLeave: new FormControl(''),
    manPowerOnInformedLeave: new FormControl(''),
    reapHarvesting: new FormControl(''),
    healthProblem: new FormControl(''),
    actOfNature: new FormControl(''),
    voluntaryAttrition: new FormControl(''),
    inVoluntaryAttrition: new FormControl(''),
    earlyAttrition: new FormControl(''),
    betterOpportunity: new FormControl(''),
    orderlessWorkplace: new FormControl(''),
    behaviouralIssue: new FormControl(''),
    repititiveMistakes: new FormControl(''),
    foundTheftDamange: new FormControl(''),
  })

  getAbsentismDetail(filter: any, pageIndex: any, pageSize: any) {
    this.reportService.getAllAbsentism(filter, pageIndex, pageSize).subscribe((res: any) => {
      if (res != null) {
        this.loader = false
        this.length = res?.responseObject.totalElements
        this.absentismData = res?.responseObject.content;
        this.chunkSize = 250;
        this.totalCalls = Math.ceil(this.totalRecords / this.chunkSize);

        this.totalRecords = this.length;
      }
    })
  }

  showConfirmationDialog() {
    this.confirmationDialogDisplay = true;
  }

  isHeaderObject(header: string | Header): header is Header {
    return (header as Header).main !== undefined;
  }

  reset() {
    this.filterForm.reset();
    const obj = this.setFilter()
    this.getAbsentismDetail(obj, 0, this.pageSize);
    this.progress = 0
    this.showExcel = false
  }

  selectRange(event: any) {
    if (this.filterForm.value.rangeDates![0]) {
      this.minDate = this.filterForm.value.rangeDates![0]
      var futureMonth = moment(this.minDate).add(1, 'M').add(15, 'days');
      var futureMonthEnd = moment(futureMonth, 'DD-MM-YYYY').format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ (India Standard Time)');
      this.maxDate = new Date(futureMonthEnd)
    }
  }


  selectWarehouse(event: any) {
    this.selectedWarehouse = []
    if (event.value.length > 0) {
      event.value.forEach((element: any) => {
        this.selectedWarehouse.push(element.id);
      });
    }
  }

  setFilter() {
    const obj = [];
    if (this.filterForm.controls.warehouse.value && this.selectedWarehouse.length > 0) {
      obj.push({
        "key": "WAREHOUSE_ID_IN",
        "value": this.selectedWarehouse.join(',')
      })
    }
    if (this.filterForm.controls.customer.value) {
      let customerValue = this.filterForm.controls.customer.value;
      obj.push({
        "key": "CUSTOMER_ID_IN",
        "value": customerValue.id
      })
    }
    if (this.filterForm.controls.rangeDates.value) {
      if (this.filterForm.controls.rangeDates.value[0]) {
        this.SelectedStartDate = moment(this.filterForm.controls.rangeDates.value[0]).format('DD-MM-yyyy ZZ')
        obj.push({
          "key": "FROM_DATE",
          dateValue: this.SelectedStartDate ? this.SelectedStartDate : ''
        })
      }
      if (this.filterForm.controls.rangeDates.value[1]) {
        this.SelectedEndDate = moment(this.filterForm.controls.rangeDates.value[1]).format('DD-MM-yyyy ZZ')
        obj.push({
          "key": "TO_DATE",
          dateValue: this.SelectedEndDate ? this.SelectedEndDate : ''
        })
      }
    }
    return obj;
  }

  applyFilter() {
    const obj = this.setFilter();
    this.getAbsentismDetail(obj, this.pageIndex, this.pageSize);
  }

  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent, this.length, this.pageIndex, this.pageIndex)
    this.allChecked = false;
    const obj = this.setFilter()
    this.getAbsentismDetail(obj, this.pageIndex, this.pageSize);
  }
  updateData(absentismData: any) {
    console.log(absentismData)
  }

  fetchDataAndCreateExcel(type: any) {
    if (type === 'excel') {
      this.showExcel = true
      // this.showReason =false
    }

    if (this.length > 50000) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Record count has surpassed the 50,000 limit. Please refine your search criteria.',
      });
      return
    }
    this.fetchDataInPages(type);
  }

  fetchDataInPages(type: any) {
    const obj = this.setFilter()
    if (this.pageIndex < this.totalCalls) {
      this.progress = this.progress === 0 ? 1 : this.progress
      this.reportService.getAllAbsentism(obj, this.pageIndex, this.chunkSize).subscribe((res: any) => {
        if(res?.responseObject?.content?.length>0){
          this.data = this.data.concat(res?.responseObject.content);
          console.log(this.data);
          this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
          this.pageIndex++;
  
          if (this.pageIndex < this.totalCalls) {
            this.fetchDataInPages(type);
          } else {
            this.createExcel(type);
          }
        }
        else{
          this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'No data found',
          });
          return
        }
      });
    }
  }

  createExcel(type: any) {
    if (this.data.length > 0 && type === 'excel') {
      const headers = [
        'S.No', 'Date', 'Customer_Name', 'Warehouse Name', 'Operational Status', 'Total manpower planned', 
        'Adhoc Manpower', 'Shift hours', 'Total manpower on uninformed leave', 'Total manpower on informed leave', 
        'Uninformed Leave Reason', '% Absentism', 'Voluntary Attrition', 'Involuntary Attrition', 
        'Early Attrition (within 6 months of joining)', 'Attrition Reason', '', '% reduction in manhours/ manpower', 
        'Total Attrition'
      ];
  
      const subHeaders = [
        '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
        'Voluntary Attrition Reasons', 'Involuntary Attrition Reasons', '', ''
      ];
  
      const filteredData = [headers, subHeaders];
  
      const filteredDataSource = this.data;
  
      filteredDataSource.forEach((element: any, index: number) => {
        const rowData = [
          index + 1,
          element.recordDate, 
          element.customerMaster?.customerName, 
          element.warehouseInfo?.plantName, 
          element.operationalStatus, 
          element.totalManpowerPlanned, 
          element.adhocManpower, 
          element.shiftHours, 
          element.totalManpowerOnInformedLeave,
          element.uninformedLeaveReason,
          element.uninformedLeaveReasonList ? this.formatNearmess.transform(element.uninformedLeaveReasonList).toString() : '',
          element.absentismPercentage, 
          element.voluntaryAttrition, 
          element.involuntaryAttrition, 
          element.earlyAttrition,
          element.voluntaryAttritionList ? this.formatNearmess.transform(element.voluntaryAttritionList).toString() : '',
          element.involuntaryAttritionList ? this.formatNearmess.transform(element.involuntaryAttritionList).toString() : '',
          element.reductionPercentage, 
          element.totalAttrition
        ];
  
        filteredData.push(rowData);
      });
  
      const ws = XLSX.utils.aoa_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
  
      const todayDateTime = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
  
      this.progress = 0;
      this.pageIndex = 0;
      this.data = [];
      this.showExcel = false;
  
      const filename = `Absentism-Attrition ${todayDateTime}.xlsx`;
  
      XLSX.utils.book_append_sheet(wb, ws, 'Absentism-Attrition');
      XLSX.writeFile(wb, filename);
    }
  }
  
}
