<div class="">
    <div class="content">
      <div class="order-details">
        <div class=" p-0">
          <div class="page-header">
            <div class="page-title" style="margin-left: -10px;">
              <h4>
                <span>
                    {{type === 'add' ? 'Add' : 'Update'}} 
                </span>
                Cutoff</h4>
            </div>
            <div class="close">
              <a  mat-dialog-close="" >
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="#333" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
              </a>
            </div>
            </div>

            <div class="card border-none custom-card">
                <div class="card-body p-0">
                    <form [formGroup]="addCutoffForm" (ngSubmit)="onSubmit(addCutoffForm)">
                        <div class="row mt-2">
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>Select Warehouse</label>
                                    <p-dropdown formControlName="warehouseName"
                                        [options]="warehouseList"
                                        [(ngModel)]="selectedWarehouse"
                                        appendTo="body"
                                        placeholder="Select Warehouse"
                                        optionValue="plantName"
                                        optionLabel="plantName"></p-dropdown>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>GR Time</label>
                                    <p-calendar 
                                        appendTo="body"
                                        inputId="calendar-timeonly" 
                                        formControlName="gr"
                                        [timeOnly]="true" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>GI Time</label>
                                    <p-calendar 
                                        appendTo="body"
                                        inputId="calendar-timeonly" 
                                        formControlName="gi"
                                        [timeOnly]="true" />
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2 mb-3">
                            <div class="col-lg-12">
                                <button class="btn btn-submit me-2" type="submit">Submit
                                    <div class="loader"> 
                                        <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                                    </div>
                                </button>
                                  <a href="javascript:void(0);" class="btn btn-cancel"  (click)="closeDialog()">Cancel</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>