import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'DatePipe'
})
export class CustomDatePipe implements PipeTransform {
  transform(value: string, format: any): any {
    // const formattedDate = moment(value, 'DD-MM-YYYY HH:mm:ss Z').format('D/M/YYYY, h:mm a');
    if(value){

      return moment(value, 'DD-MM-YYYY HH:mm:ss Z').format('DD-MM-YYYY, hh:mm a');
    }else{
      return ''
    }

  }
}
