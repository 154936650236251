import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import * as feather from 'feather-icons';
import { roleService } from '../../services/rolebase-services';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit{
  isMobile: boolean = false;
  isModuleActive:boolean=false;
  isSubModuleActive:boolean= false;
  changePassDialogDisplay: any;
  userDetails:any;
  userRole:any; 
  theme = 'dark'
  themeValue = false;
  customerName:any;
  constructor(private auth: AuthService, public roleService: roleService,private elementRef: ElementRef,public themeService: ThemeService) {
    this.checkIfMobile();
    const getUserDetails:any = this.auth.getUserDetails() ? this.auth.getUserDetails() : localStorage.getItem('user_details');
    this.userDetails = JSON.parse(getUserDetails)
  }
  
  ngAfterViewInit() {
    feather.replace();
  }

  ngOnInit() {
    const theme = localStorage.getItem('darkTheme')
    this.themeValue = theme === 'true' ? true : false
    this.userRole = this.userDetails.role
    this.roleService.fetchRoleList();
    const customerDataString = localStorage.getItem('customerList');
    
    if (customerDataString) {
        const customerDataArray = JSON.parse(customerDataString);
        this.customerName = customerDataArray[0]?.customerName || '';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile(); // Check whenever the window is resized
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 769; 
  }

  toggleNavMobile() {
    if (this.isMobile) {
      const toggleBtn: any = document.getElementById('mobile_btn');

      const body = document.body;
      // const wrapper = document.querySelector(".main-wrapper");

      if (document.querySelector(".slide-nav")) {
        document.querySelector(".main-wrapper")?.classList.remove("slide-nav");

        body.classList.remove('menu-opened');
        // toggleBtn.classList.add('active');
        // localStorage.setItem(screenModeNightTokenState, 'night');
        setTimeout(() => {
          body.classList.remove('mini-sidebar');
          document.querySelector(".header-left")?.classList.add("active");
        }, 100);
      } else {
        document.querySelector(".main-wrapper")?.classList.add("slide-nav");

        body.classList.add('menu-opened');
        toggleBtn.classList.remove('active');
        // localStorage.removeItem(screenModeNightTokenState);
        setTimeout(() => {
          body.classList.add('mini-sidebar');
          document.querySelector(".header-left")?.classList.remove("active");
        }, 100);
      }
    }
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  logOut() {
    localStorage.setItem('darkTheme', 'false');
    document.body.classList.remove('dark-theme');
    document.body.classList.add('light-theme');
    this.auth.logOut()
  }
  onConfirmation(choice: boolean) {
    this.elementRef.nativeElement.ownerDocument.body.style.pointerEvents = '';
    if (choice) {
      this.changePassDialogDisplay = false;
      // Proceed with the update
      console.log('Updating...');
    } else {
      this.changePassDialogDisplay = false;
      // Cancel the update
      console.log('Update cancelled.');
    }
  }
  showChangePassDialog() {
    this.elementRef.nativeElement.ownerDocument.body.style.pointerEvents = 'none';
    // this.changePassDialogDisplay = true;
    this.changePassDialogDisplay = true
  }
}
