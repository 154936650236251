import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdaterService } from '../../updater.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-add-update-pms-kpi',
  templateUrl: './add-update-pms-kpi.component.html',
  styleUrls: ['./add-update-pms-kpi.component.css']
})
export class AddUpdatePmsKpiComponent {
  submittedloader: boolean = false;
  checkUser: boolean = false;
  warehouseList: any[] = []
  userId: any
  customerName: any;
  sendSelectedWarehouse: any[] = [];
  pmsDetail: any[] = []
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AddUpdatePmsKpiComponent>, private updateService: UpdaterService, private messageService: MessageService) { }

  pmsForm = new FormGroup({
    primaryKey: new FormControl(),
    warehouseName: new FormControl('', [Validators.required]),
    customerName: new FormControl({ value: '', disabled: true }),
    pmsDate: new FormControl(),
    opsDay: new FormControl(),
    pmsInwardQty: new FormControl(),
    pmsOutwardQty: new FormControl(),
    pmsStatus: new FormControl(),
    remarkNotUpdation: new FormControl(),
    pmsCycleCount: new FormControl(),
    pmsPhysicalStockQty: new FormControl(),
    pmsActualStockQty: new FormControl(),
    pmsDiscremencyRemark: new FormControl(),
  })

  ngOnInit(): void {
    const warehouselist = JSON.parse(localStorage.getItem('warehouseList') || '[]');
    this.warehouseList = warehouselist ? warehouselist : [];
    this.userId = null

    const customerDataString = localStorage.getItem('customerList');
    this.customerName = '';
    if (customerDataString) {
      const customerDataArray = JSON.parse(customerDataString);
      this.customerName = customerDataArray[0]?.customerName || '';
    }

    const dateString = this.data.pmsData.pmsDate; // e.g., "21-08-2024"
    const [day, month, year] = dateString.split('-');
    const formattedDate = new Date(`${year}-${month}-${day}`);

    const patchedData = {
      ...this.data.pmsData,
      warehouseName: this.data.pmsData.warehouseName,
      pmsDate: formattedDate
    }
    this.pmsForm.patchValue(patchedData)

    console.log(this.data.pmsData);
  }


  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(data: any) {
    const pmsDate = this.pmsForm.controls.pmsDate?.value;
    let istDate = null;
    if (pmsDate) {
      istDate =  moment(pmsDate).format('DD-MM-YYYY HH:mm:ss Z').replace(/(\+|-)(\d{2}):(\d{2})/, '$1$2$3');
    }
    const dataToSend = {
      primaryKey: this.pmsForm.value?.primaryKey ? this.pmsForm.value?.primaryKey : null,
      warehouseName: this.pmsForm.controls.warehouseName?.value,
      customerName: this.pmsForm.controls.customerName?.value,
      pmsDate: istDate,
      remarkNotUpdation: this.pmsForm.controls.remarkNotUpdation?.value,
      pmsCycleCount: this.pmsForm.controls?.pmsCycleCount?.value,
      pmsPhysicalStockQty: this.pmsForm.controls?.pmsPhysicalStockQty?.value,
      pmsActualStockQty: this.pmsForm.controls?.pmsActualStockQty?.value,
      pmsDiscremencyRemark: this.pmsForm.controls?.pmsDiscremencyRemark?.value
    }

    this.updateService.addUpdatePmsKPI(dataToSend).subscribe((res: any) => {
      if (res && res.responseStatus) {
        this.dialogRef.close({ responseStatus: true });
      } else {
        this.dialogRef.close({ responseStatus: false, error: res.error || 'Unknown error' });
      }
    },
    (error: any) => {
      this.messageService.add({severity: 'error',summary: 'Error',detail: 'Record submission failed. Please check the details and try again.',
      });
    }
    )
  }
  selectWarehouse(event: any) {
    this.sendSelectedWarehouse = [{
      warehouseId: event.value
    }]
  }


}
