import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateCalculationComponent } from '../update-calculation/update-calculation.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MasterService } from '../../master.service';
import { PageEvent } from '@angular/material/paginator';
import { MessageService } from 'primeng/api';
import { AddCalculationComponent } from '../add-calculation/add-calculation.component';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/shared/services/order-service';

interface Type {
  type: String
}

interface FileType {
  type: string;
}

@Component({
  selector: 'app-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.css']
})

export class CalculationComponent implements OnInit {
  minDate: any
  maxDate: any;
  allChecked: boolean = false;
  length = 0;
  pageSize = 20;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 30, 40, 50];
  pageEvent: any;
  totalRecords!: number;
  theme = 'dark'
  showExcel = false;
  progress: number = 0;
  loader: boolean = false;
  calculationData: any;
  defaultLogicData: any;
  type: Type[] | any;
  fileType: FileType | any;
  customerList: any[] = [];
  clientId: any;
  mappedHeaderData: any[] = [];
  calculationDetail: any[] = [];
  id:any
  customerName:any
  headerMappings: any[] = [];
  clientFileType: any;
  clientFileTypeId: any;
  constructor(public dialog: MatDialog, private fb: FormBuilder, private masterService: MasterService, private messageService: MessageService,private router: Router, 
    private OrderService: OrderService
  ) { }

  filterForm = this.fb.group({
    logicType: new FormControl(),
    customerName: new FormControl(),
    fileType: new FormControl()
  })

  ngOnInit(): void {
    const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
    this.clientId = JSON.parse(localStorage.getItem('clientId')!);
    this.customerList = customerList ? customerList : [];
    
    this.getClientColName('GR280', this.clientId);
    this.getClientColName('GI90', this.clientId);

    setTimeout(() => {
      const obj = this.setFilter();
      this.getdefaultLogicDetails(obj, this.pageIndex, this.pageSize);
    }, 500)
    this.type = [
      { label: 'Default', value: 'default_logic' },
      { label: 'User', value: 'user_logic' }
    ];

    this.fileType = [
      { label: 'GR', value: 'GR' },
      { label: 'GI', value: 'GI' },
    ]

  }

  getClientColName(fileType: any, customerId: any){
    this.OrderService.getFieldMappingDetails(fileType, customerId).subscribe((res: any) => {
      this.mappedHeaderData = res.responseObject[0].fileFieldMappings;
      this.clientFileType = res?.responseObject[0].fileType;
      this.clientFileTypeId = res?.responseObject[0].id;

      console.log(res.responseObject)
      this.id = res.responseObject[0].id
       this.headerMappings = [];
       this.mappedHeaderData.forEach((header: any) => {
         this.headerMappings[header.headerName] = {
           id: header.id,                          
           fieldName: header.fieldName,            
           fieldType: header.fieldType,            
           databaseHeader: header.fieldName        
         };

        });
    })
    
  }

  getdefaultLogicDetails(filter: any, pageIndex: any, pageSize: any) {
    this.masterService.getdefaultLogicDetails(filter, pageIndex, pageSize).subscribe((res: any) => {
      if (res != null) {
        this.loader = false;
        this.length = res?.responseObject.totalElements;
        this.totalRecords = this.length;
        this.calculationDetail = res?.responseObject?.content
        // Create a map to combine user_logic and default_logic
        const combinedDataMap = new Map<string, any>();

        res.responseObject.content.forEach((row: any) => {
          const key = row.tblColumnName;
          if (combinedDataMap.has(key)) {
            const existingRow = combinedDataMap.get(key);

            if (row.logicType === 'user_logic') {
              existingRow.formula = row.formula;
              existingRow.userLogicId = row.id; // Store the id for user_logic
            } else if (row.logicType === 'default_logic') {
              existingRow.defaultFormula = row.formula;
              existingRow.defaultLogicId = row.id; // Store the id for default_logic
            }
          } else {
            const newRow = {
              clientName: row.clientName,
              fileType: row.fileType,
              tblColumnName: row.tblColumnName,
              formula: row.logicType === 'user_logic' ? row.formula : null,
              defaultFormula: row.logicType === 'default_logic' ? row.formula : null,
              userLogicId: row.logicType === 'user_logic' ? row.id : null, // Store id
              defaultLogicId: row.logicType === 'default_logic' ? row.id : null // Store id
            };
            combinedDataMap.set(key, newRow);
          }
        });

        this.calculationData = Array.from(combinedDataMap.values());

        this.calculationData.forEach((row: any) => {
          this.mappedHeaderData.forEach((el: any) => {
              if (el.fieldName === row?.tblColumnName) {
                  row.clientColumnName = el.headerName; 
              }
          });
      });
      }
    });
  }

  updateCalculation(data: any,type:any): void {
    let dialogRef = this.dialog.open(UpdateCalculationComponent, {
      panelClass: 'custom-modalbox',
      data: {
        "component": "calculation",
        updateData: data,
        type: type,
        id: data.userLogicId || data.defaultLogicId,
        fileType: this.clientFileType,
        clientFileTypeId: this.clientFileTypeId,
        clientId: this.clientId
      }
    });
    console.log(data)
    dialogRef.afterClosed().subscribe((result) => {
      const obj = this.setFilter()
      this.getdefaultLogicDetails(obj, 0, this.pageSize)
    })
  }

  addCalculation(): void {
    let dialogRef = this.dialog.open(AddCalculationComponent, {
      panelClass: 'custom-modalbox',
      data: {
        clientId: this.clientId
      }
    })
  }

  resetRecord(reset: any) {
    const resetObj = {
      "logicType": "user_logic",
      "fileType": reset.fileType,
      "clientName": reset.clientName,
      "tblColumnName": reset.tblColumnName
    }

    console.log(resetObj)
    this.masterService.resetCalculationLogic(resetObj).subscribe((res: any) => {
      if (res != null) {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Calculation reset successfully',
        });
      }
    })
    const obj = this.setFilter()
    this.getdefaultLogicDetails(obj, 0, this.pageSize)
  }

  reset() {
    this.filterForm.reset();
    const obj = this.setFilter()
    this.getdefaultLogicDetails(obj, 0, this.pageSize)
    this.progress = 0
  }

  applyFilter() {
    const obj = this.setFilter();
    this.getdefaultLogicDetails(obj, this.pageIndex, this.pageSize);
  }


  setFilter() {
    const obj = [];
    if (this.filterForm.controls.customerName) {
      let customerValue = this.filterForm.controls.customerName.value?.customerName;

      if (customerValue) {
        customerValue = customerValue.charAt(0).toUpperCase() + customerValue.slice(1).toLowerCase();
      }
      obj.push({
        "key": "CUSTOMER_ID_IN",
        "value": customerValue
      })
    }
    if (this.filterForm.controls.fileType) {
      let fileTypeValue = this.filterForm.controls.fileType.value;
      obj.push({
        "key": "FILE_TYPE",
        "value": fileTypeValue
      })
    }
    if (this.filterForm.controls.logicType) {
      let logicTypeValue = this.filterForm.controls.logicType.value;
      obj.push({
        "key": "REPORT_TYPE",
        "value": logicTypeValue
      })
    }
    let customerValue = this.filterForm.controls.customerName.value?.customerName
      || (this.customerList.length > 0 ? this.customerList[0].customerName : '');

    if (customerValue) {
      customerValue = customerValue.charAt(0).toUpperCase() + customerValue.slice(1).toLowerCase();
      obj.push({
        "key": "CUSTOMER_ID_IN",
        "value": customerValue
      });
    }


    return obj;
  }

  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.allChecked = false;
    const obj = this.setFilter()
    this.getdefaultLogicDetails(obj, this.pageIndex, this.pageSize);
  }

}
