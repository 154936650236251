  import { Component, Output, EventEmitter, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
  import * as moment from 'moment';
  import { Router } from '@angular/router';
  // import { AuthService } from 'src/app/shared/services';
  
  @Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.css']
  })
  export class dateRangeComponent implements OnInit {
    @Input() filterConditionForCalendar: any;
    // @Input() getRefrence;
    selectedStartDate: any;
    selectedEndDate: any;
    maxDate: any = new Date();
    minDate: any = new Date(
      moment()
        .subtract(180, 'days')
        .startOf('day')
        .valueOf()
    );
    @Output() getCustomDates = new EventEmitter();
    notValidStartDate: Boolean = false;
    notValid: Boolean = false;
    // currentRoute: string;
    // @ViewChild('hideDiv', { read: ElementRef, static: true }) hideDiv: ElementRef;
    accountslug: any;
  
    constructor(private el: ElementRef, private router: Router) {}
  
    ngOnChanges() {
      // if (this.filterConditionForCalendar) {
      // if ('date' in this.filterConditionForCalendar && this.filterConditionForCalendar.date.conditionType != 'custom') {
      this.selectedStartDate = '';
      this.selectedEndDate = '';
      // }
      // }
    }
  
    ngOnInit() {
      // this.accountslug = this.authService.getAccountSlug();
      // this.currentRoute = this.router.url;
    }
  

    /**@description- Method Called on Selection of From Date*/
    public onSelectFromDate(event:any) {
      this.selectedStartDate = event;
      if (this.selectedEndDate) this.checkDates();
    }
  
    /**@description - Method Called on Selection of To Date*/
    public onSelectToDate(event:any) {
      this.selectedEndDate = event;
      this.checkDates();
    }
  
    /**@description - Method Called on Selection of To Date To Validate Dates*/
    public checkDates() {
      const rangeValidate = this.accountslug === 'eshop' ? 31 : 180;
      this.notValid = false;
      this.notValidStartDate = false;
      const diffTime = Math.abs(this.selectedEndDate - this.selectedStartDate);
      if (this.selectedStartDate > this.selectedEndDate) this.notValidStartDate = true;
      else if (Math.ceil(diffTime / (1000 * 60 * 60 * 24)) > rangeValidate) this.notValid = true;
    }
  
    /**@description - Method Called on Apply*/
    public onSubmitDates() {
      if (this.selectedStartDate && this.selectedEndDate) {
        this.hideDivOnButtonClick();
        this.getCustomDates.emit({
          dateType: 'custom',
          fromDate: this.selectedStartDate,
          toDate: this.selectedEndDate,
          filterCondition: this.filterConditionForCalendar,
          conditionType: 'custom',
          // filterKey: this.getFilterKeys(this.filterConditionForCalendar),
          // displayKey: this.getDisplayKeys(this.filterConditionForCalendar)
        });
      }
      // this.getRefrence.close();
    }
  
    /**@description - Method To Hide Component*/
    public hideDivOnButtonClick() {
      // this.hideDiv.nativeElement.classList.add('hide-component');
      setTimeout(() => {
        // this.hideDiv.nativeElement.classList.remove('hide-component');
      }, 1000);
    }
  }
  
