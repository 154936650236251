<!-- Main Wrapper -->
<div class="content">
    <div class="page-header" style="margin-left: 0px;">
        <div class="page-title">
            <div *ngIf="data?.isUpdate === false">
                <h4>Import Reasons</h4>
                <h6>Bulk upload your reasons</h6>
            </div>
            <div *ngIf="data?.isUpdate === true">
                <h4>Update Reason</h4>
            </div>
        </div>

        <div class="close">
            <a mat-dialog-close="">
                <svg viewBox="0 0 24 24" width="20" height="20" stroke="#333" stroke-width="2" fill="none"
                    stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </a>
        </div>
    </div>

    <!-- /product list -->
    <div class="card"  *ngIf="data?.isUpdate === false">
        <div class="card-body">
            <div>
                <div class="requiredfield">
                    <h4>Field must be in excel format</h4>
                </div>
                <div class="row flex-column">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="uploads">
                                    Upload Excel File
                                    <div class="image-upload mt-1">
                                        <div class="image-uploads">
                                            <img src="assets/img/icons/upload.svg" alt="img">
                                            <h4>Drag and drop a file to upload</h4>
    
                                            <input type="file" id="uploads" name="uploads" (change)="selectFile($event)"
                                                accept=".xlsx, .xls" #uploadInput>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12" *ngIf="fileType === 'FE_AUDIT'">
                            <div class="form-group mt-3">
                                <h6 class="mb-2">Download Sample file</h6>
                                <a href="../../../../assets/file/feAuditSample2.xlsx" class="btn btn-submit"
                                    download="">Download</a>
    
                            </div>
                        </div>
                    </div>
                    <div *ngIf="progress>0" class="progress mb-4 col-lg-6 col-sm-6 col-12">
                        <div class="progress-bar progress-bar-info" role="progressbar" attr.aria-valuenow="{{ progress }}"
                            aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: progress + '%' }">
                            {{ progress }}%
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group mb-0">
                            <button [disabled]="!selectedFiles?.length" (click)="upload(fileType)"
                                class="btn btn-submit me-2">Submit</button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <div class="order-details" *ngIf="data?.isUpdate === true">
        <div class="card border-none">
            <div class="card-body p-0">
                <form [formGroup]="updateForm" (ngSubmit)="onSubmit(updateForm)">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Category Type</label>
                                <input type="text" formControlName="categoryType">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                              <label>Issue Type</label>
                              <p-dropdown [options]="issueTypes" formControlName="issueType"></p-dropdown>
                            </div>
                          </div>
                          
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Reason</label>
                                <textarea type="text" rows="2" formControlName="reason"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 mb-3">
                        <div class="col-lg-12">
                            <button class="btn btn-submit me-2" type="">Submit
                                <div class="loader">
                                    <mat-spinner [diameter]="25" [strokeWidth]="2"
                                        class="centre-align"></mat-spinner>
                                </div>
                            </button>
                           
                            <a href="javascript:void(0);" class="btn btn-cancel"
                                (click)="closeDialog()">Cancel</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
