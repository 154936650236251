import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { FormatForExcelNearmissPipe } from 'src/app/_pipe/format-for-excel-nearmiss.pipe';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/shared.service';
import * as XLSX from 'xlsx';
import { UpdaterService } from '../../updater.service';

@Component({
  selector: 'app-pick-fail-kpi',
  templateUrl: './pick-fail-kpi.component.html',
  styleUrls: ['./pick-fail-kpi.component.css']
})
export class PickFailKpiComponent {
  minDate: any
  maxDate: any;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  pageEvent: any;
  totalRecords!: number;
  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;
  loader: boolean = false;
  warehouseList: any[] = [];
  customerList: any[] = [];
  selectedWarehouse: any;
  selectedCustomer: any;
  userRole: any;
  warehouseId: any;
  userDetails: any;
  pmsData: any = [];
  SelectedStartDate: any;
  SelectedEndDate: any;
  isChecked: boolean = true;
  processedAbsentismList: any = [];
  confirmationDialogDisplay: boolean = false;
  progress: number = 0;
  showExcel = false;
  showReason = false;
  data: any[] = [];
  totalCalls: any;
  chunkSize: any;
  selectedData: any[] = [];
  theme = 'dark'
  constructor(private fb: FormBuilder, public dialog: MatDialog, private auth: AuthService,
    private datePipe: DatePipe, private messageService: MessageService, private sharedService: SharedService,
    private formatNearmess: FormatForExcelNearmissPipe, private updateService: UpdaterService,
    private confirmationService: ConfirmationService) {

  }

  ngOnInit(): void {
    const warehouselist = JSON.parse(localStorage.getItem('warehouseList') || '[]');

    const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
    this.customerList = customerList ? customerList : [];

    this.warehouseList = warehouselist ? warehouselist : [];
    this.selectedWarehouse = []
    if (this.userRole === 'ROLE_USER') {
      this.warehouseId = this.userDetails.userWarehouseMapping[0].warehouseId
      this.warehouseList.filter((el: any) => {
        if (el.id === this.warehouseId) {
          this.selectedWarehouse.push(el.plantName)
        }
      })
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }
    else {
      this.selectedWarehouse = []
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }

    const obj = this.setFilter();
    this.getPickFailDetails(obj, this.pageIndex, this.pageSize);
  }
  filterForm = this.fb.group({
    // delivery: new FormControl(''),
    // rangeDates: new FormControl(''),
    warehouse: new FormControl(),
    customer: new FormControl()
  });


  getPickFailDetails(filter: any, pageIndex: any, pageSize: any) {
    this.updateService.getPickFailDetails(filter, pageIndex, pageSize).subscribe((res: any) => {
      if (res != null) {
        this.loader = false;
        this.length = res?.responseObject.totalElements;
        this.pmsData = res?.responseObject.content;
        this.totalRecords = this.length;
        this.chunkSize = 250;
        this.totalCalls = Math.ceil(this.totalRecords / this.chunkSize);

        console.log(this.pmsData)
      }
    })
  }

  selectWarehouse(event: any) {
    this.selectedWarehouse = []
    if (event.value.length > 0) {
      event.value.forEach((element: any) => {
        this.selectedWarehouse.push(element.plantName);
      });
    }
  }

  reset() {
    this.filterForm.reset();
    const obj = this.setFilter();
    this.getPickFailDetails(obj, this.pageIndex, this.pageSize);
    this.progress = 0
    this.showExcel = false
  }

  setFilter() {
    const obj = [];
    if (this.filterForm.controls.warehouse.value && this.selectedWarehouse.length > 0) {
      obj.push({
        "key": "WAREHOUSE_IN",
        "value": this.selectedWarehouse.join(',')
      })
    }
    if (this.filterForm.controls.customer.value) {
      let customerValue = this.filterForm.controls.customer.value;
      obj.push({
        "key": "CUSTOMER_ID_IN",
        "value": customerValue.customerName
      })
    }

    return obj;
  }

  applyFilter() {
    const obj = this.setFilter();
    this.getPickFailDetails(obj, this.pageIndex, this.pageSize);
  }

  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent, this.length, this.pageIndex, this.pageIndex)
    this.allChecked = false;
    const obj = this.setFilter();
    this.getPickFailDetails(obj, this.pageIndex, this.pageSize);
  }

  showConfirmationDialog(data:any): void {
    this.selectedData = this.selectedData || [];
    if(this.selectedData || data){
      this.selectedData.push(data)
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected records?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteSelected();
      },
      reject: () => {

      }
    });
  }

  onCheckboxChange(event: any, row: any): void {
    if (event.checked) {
      this.selectedData.push(row);
    } else {
      this.selectedData = this.selectedData.filter(item => item !== row); // Remove deselected row
    }
  }

  isSelected(row: any): boolean {
    return this.selectedData.includes(row);
  }

  deleteSelected(): void {
    if (this.selectedData?.length > 0) {
      const primaryKeys = this.selectedData.map((row: any) => row.primaryKey).join(',');

      this.updateService.deletePickFailDetails(primaryKeys).subscribe({
        next: (response: any) => {
          this.pmsData = this.pmsData.filter((row: any) => !this.selectedData.some(selectedRow => selectedRow.primaryKey === row.primaryKey));
          this.selectedData = [];
          const obj = this.setFilter();
          this.getPickFailDetails(obj, this.pageIndex, this.pageSize);
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Selected records have been deleted successfully.' });
        },
        error: (error: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete the selected records.' });
        }
      });
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'No records selected for deletion.' });
    }
  }
  
  fetchDataAndCreateExcel(type: any) {
    if (type === 'excel') {
      this.showExcel = true
      // this.showReason =false
    }

    if (this.length > 50000) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Record count has surpassed the 50,000 limit. Please refine your search criteria.',
      });
      return
    }
    this.fetchDataInPages(type);
  }

  fetchDataInPages(type: any) {
    const obj = this.setFilter()
    if (this.pageIndex < this.totalCalls) {
      this.progress = this.progress === 0 ? 1 : this.progress
      this.updateService.getPickFailDetails(obj, this.pageIndex, this.chunkSize).subscribe((res: any) => {
        if (res?.responseObject?.content?.length > 0) {
          this.data = this.data.concat(res?.responseObject.content);
          console.log(this.data);
          this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
          this.pageIndex++;

          if (this.pageIndex < this.totalCalls) {
            this.fetchDataInPages(type);
          } else {
            this.createExcel(type);
          }
        }
        else {
          this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'No data found',
          });
          return
        }
      });
    }
  }

  createExcel(type: any) {
    if (this.data.length > 0 && type === 'excel') {
      const headers = [
        'S.No', 'Primary Key', 'Customer_Name', 'Warehouse Name', 'Do Date', 'Do No.', 'Deletion Date',
        'Delation Time', 'Item', 'Material', 'Warehouse Remark', 'Quantity'
      ];

      const filteredData = [headers];

      const filteredDataSource = this.data;

      filteredDataSource.forEach((element: any, index: number) => {
        const rowData = [
          index + 1,
          element.primaryKey,
          element.customerName,
          element.warehouseName,
          element.doDate,
          element.doNumber,
          element.deletionDate,
          element.deletionTime,
          element.item,
          element.material,
          element.warehouseRemark,
          element.qty
        ];

        filteredData.push(rowData);
      });

      const ws = XLSX.utils.aoa_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();

      const todayDateTime = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });

      this.progress = 0;
      this.pageIndex = 0;
      this.data = [];
      this.showExcel = false;

      const filename = `Pick fail KPI ${todayDateTime}.xlsx`;

      XLSX.utils.book_append_sheet(wb, ws, 'Pick fail KPI');
      XLSX.writeFile(wb, filename);
    }
  }





}
