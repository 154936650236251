import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { Configuration } from '../constants/constants/config';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdaterService {

  constructor(private api : ApiService,private API_URL: Configuration,private http: HttpClient) { }

  getPmsInboundDetails(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_PMS_INBOUND_DETAILS}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  getPmsOutboundDetails(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_PMS_OUTBOUND_DETAILS}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  getPmsKpiDetails(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_PMS_KPI_DETAILS}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  getPickFailDetails(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_PICK_FAIL_DETAILS}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  deletePmsOutboundDetails(records:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.DELETE_PMS_OUTBOUND_DETAILS}?primaryKey=${records}`;
    return this.api.delete(url);
  }

  deletePmsInboundDetails(records:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.DELETE_PMS_INBOUND_DETAILS}?primaryKey=${records}`;
    return this.api.delete(url);
  }

  deletePickFailDetails(records:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.DELETE_PICK_FAIL_DETAILS}?primaryKey=${records}`;
    return this.api.delete(url);
  }

  deletePmsKpiDetails(records:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.DELETE_PMS_KPI_DETAILS}?primaryKey=${records}`;
    return this.api.delete(url);
  }
  addUpdatePmsKPI(data:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_UPDATE_PMS_KPI}`;
    return this.api.post(url, data);
  }
}
