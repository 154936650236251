import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as feather from 'feather-icons';
import { Subscription } from 'rxjs';
import { SharedService } from './shared/shared.service';
import { RouterService } from './shared/services/router.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'Dashboards';
  showLoader = false;
  showLoader1 = false;
  loaderSubscription!: Subscription;
  loaderSubscription1!: Subscription 
  constructor(private _route: Router,
    private sharedService: SharedService,
    private elementRef: ElementRef, private routerService: RouterService
    ){
    const user_details = localStorage.getItem('user_details');
    if(user_details){
      localStorage.setItem('isloggedin','true')
    }else{
      localStorage.setItem('isloggedin','false')
    }
    // const isloggedIn =  localStorage.getItem('isloggedin')
    // console.log("isloggedIn ppppp",isloggedIn)
    // if(isloggedIn === 'true'){
      // this._route.navigate(['/order']);
    // }else{
      // this._route.navigate([''])
    // }
  }
  ngOnInit(): void {
    this.routerService.ActivateRouteService();
    this.loaderSubscription = this.sharedService.loaderState$.subscribe((state: any) => {
      this.showLoader = state;
    });
    this.loaderSubscription1 = this.sharedService.loaderState1$.subscribe((state: any) => {
      this.showLoader1 = state;
    });
  }

  ngAfterViewInit() {
    feather.replace();
  }
}
