import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDropDownFilter'
})
export class searchDropDownFilter implements PipeTransform {
  
  transform(items: any[], searchText: string, filterKey: string = ''): any[] {
    if (!items || !searchText) {
      return items || []; 
    }

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      if (filterKey && item[filterKey]) {
        return item[filterKey].toLowerCase().includes(searchText);
      } else {
        for (const key in item) {
          if (item.hasOwnProperty(key) && item[key].toString().toLowerCase().includes(searchText)) {
            return true;
          }
        }
        return false;
      }
    });
  }
}