// token.interceptor.ts

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  authRequest:any;
  constructor(private auth : AuthService,
    public sharedService: SharedService,){

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the authentication token from wherever you store it (e.g., localStorage, a service, etc.)
    // console.log("localStorage authToken",localStorage.getItem('access_token'))
    this.sharedService.updateLoader(true);
    const authToken = this.auth.getAuthToken() ? this.auth.getAuthToken() : localStorage.getItem('access_token') 
    var apidetector = request.url.split('/')[request.url.split('/').length - 1].split('?')[0];
    const requestToHandle = authToken
            ? request.clone({
                headers: request.headers.set('Authorization', `Bearer ${authToken}`),
                setHeaders: { 'DEVICE-TYPE': 'Web',VER:'1' },
            })
            : request;
            return next.handle(requestToHandle).pipe(tap((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                  if (event.status && (event.status === 200 || 201)) {
                    this.sharedService.updateLoader(false);
                  }
              }
          }, (error:any) => {
              this.sharedService.updateLoader(false);
              if (error.status === 401) {
              } else {
                  // return throwError(error);
              }
          }));
  }
}

export let tokenProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor, 
  multi: true,
};