<div class="page-wrapper" [ngClass]="[theme === 'dark'? 'dark' : '']">
    <div class="content">
        <div class="order-details">
            <div class="content p-0">
                <div class="page-header">
                    <div class="page-title d-flex justify-content-between w-100">
                        <h4>PMS Outbound</h4>
                        <div class="pagination">
                            <button *ngIf="!showExcel" class="Printall-label btn btn-submit"
                                (click)="fetchDataAndCreateExcel('excel')">
                                <svg viewBox="0 0 24 24" width="18" height="18" stroke="#fff" stroke-width="2"
                                    fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                    <polyline points="6 9 6 2 18 2 18 9"></polyline>
                                    <path
                                        d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2">
                                    </path>
                                    <rect x="6" y="14" width="12" height="8"></rect>
                                </svg> Excel
                            </button>
                            <p-progressBar *ngIf="showExcel && progress>0" [value]="progress"
                                [style]="{width:'150px',height: '35px'}" />
                            <p-progressBar *ngIf="!showExcel && progress>0" [value]="progress"
                                [style]="{width:'150px',height: '35px',margin: '0 0 0 15px'}" />
                        </div>
                    </div>
                </div>


                <!-- Absentisum & attrition -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top position-relative">
                            <form [formGroup]="filterForm">
                                <div class="search-set justify-content-between w-100">
                                    <div class="search-path page-header ">
                                        <a class="btn btn-cancel" (click)="reset()">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                            Reset
                                        </a>
                                        <a class="btn btn-added ms-3" (click)="op.toggle($event)" icon="pi pi-image"
                                            label="Show">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                                                </path>
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                                                </path>
                                            </svg>
                                            Filter Data</a>
                                    </div>
                                    <div class="filterCard flex justify-content-center" style="width: 35vw;">
                                        <p-overlayPanel #op [showCloseIcon]="true" [dismissable]='false'>
                                            <ng-template pTemplate="content">
                                                <div class="card">
                                                    <h4 class="filterHeader">Filter Data</h4>
                                                    <div class="row">

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Warehouse</label>
                                                                <p-multiSelect [options]="warehouseList"
                                                                    formControlName="warehouse"
                                                                    placeholder="Select a Warehouse"
                                                                    optionLabel="plantName"
                                                                    (onChange)="selectWarehouse($event)"></p-multiSelect>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Customer</label>
                                                                <p-dropdown formControlName="customer"
                                                                    [options]="customerList"
                                                                    placeholder="Select Customer"
                                                                    optionLabel="customerName"></p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="search-path page-header ">
                                                        <a class="btn btn-primary" id="filter_search"
                                                            (click)="op.toggle(false);applyFilter()">
                                                            <svg viewBox="0 0 24 24" width="18" height="18"
                                                                stroke="currentColor" stroke-width="2" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="css-i6dzq1">
                                                                <circle cx="11" cy="11" r="8"></circle>
                                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                            </svg>
                                                            Apply Filter
                                                        </a>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-overlayPanel>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="table-responsive">
                            <table class="table order-data">
                                <thead class="table-head">
                                    <tr>
                                        <ng-container>
                                            <th class="selctorder">
                                                <ng-container>
                                                    <span class="me-2 text-center date-width"
                                                        [ngClass]="{disabled: selectedData.length === 0}"
                                                        [ngStyle]="{'opacity': selectedData.length === 0 ? '0' : '1'}">
                                                        <svg matTooltip="Delete selected"
                                                            *ngIf="selectedData.length > 0"
                                                            (click)="showConfirmationDialog('')" width="20" height="20"
                                                            viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V8H21V6H17V4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2H9C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V6H3V8H5V20ZM9 4H15V6H9V4ZM8 8H17V20H7V8H8Z"
                                                                fill="#EA5455" />
                                                            <path d="M9 10H11V18H9V10ZM13 10H15V18H13V10Z"
                                                                fill="#EA5455" />
                                                        </svg>

                                                    </span>
                                                </ng-container>
                                            </th>
                                            <th>Action</th>
                                            <th>No.</th>
                                            <th>Primary Key</th>
                                            <th>Customer Name</th>
                                            <th>Warehouse Name</th>
                                            <th>Warehouse</th>
                                            <th>Goods Out Date</th>
                                            <th>Timeout</th>
                                            <th>Pack Code</th>
                                            <th>Outbound Section</th>
                                            <th>Outbound Place</th>
                                            <th>Outbound Qty</th>
                                            <th>Inputer</th>
                                            <th>Outbound Amount</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="!loader">
                                        <tr *ngFor="let row of pmsData">
                                            <td class="selctorder">
                                                <mat-checkbox (change)="onCheckboxChange($event, row)"
                                                    [checked]="isSelected(row)"></mat-checkbox>
                                            </td>
                                            <td>
                                                <a class="me-3 d-flex justify-content-center" (click)="showConfirmationDialog(row)">
                                                    <img matTooltip="Delete"
                                                        src="../../../../assets/img/icons/delete.svg" alt="img">
                                                </a>
                                            </td>
                                            <td>{{ row.no }}</td>
                                            <td>{{ row.primaryKey }}</td>
                                            <td>{{ row.customerName }}</td>
                                            <td>{{ row.warehouseName }}</td>
                                            <td>{{ row.warehouse }}</td>
                                            <td>{{row.goodsOutDate}}</td>
                                            <td>{{row.timeOut}}</td>
                                            <td>{{row.packCode}}</td>
                                            <td>{{row.outboundSection}}</td>
                                            <td>{{row.outboundPlace}}</td>
                                            <td>{{row.outboundQty}}</td>
                                            <td>{{ row.inputer}}</td>
                                            <td>{{row.outboundAmount}}</td>
                                        </tr>
                                        <tr *ngIf="pmsData?.length == 0">
                                            <div class="no-order pt-4 pb-4">
                                                <p class="margin-0">No data found</p>
                                            </div>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="pagination search-set justify-content-end flex-end w-100">
                    <mat-paginator class="demo-paginator" (page)="pageNavigations($event)" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]" aria-label="Select page">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</div>
<p-toast/>
<p-confirmDialog  [position]="'top'" rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>