export const environment = {
  production: false,
  // BASE_DOMAIN:'http://localhost:3000/'
  BASE_DOMAIN:'https://dev.goalfa.in/',
  LOGIN_DOMAIN: 'https://dev.goalfa.in/',
  COOKIE_DOMAIN: 'localhost',
  ORDER_URL: 'https://dev.goalfa.in/',
  BASE_URL: 'https://dev.goalfa.in/havells-service/',
  MAIN_URL: 'https://dev.goalfa.in/'
};
