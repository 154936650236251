import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'custom-date-range',
  templateUrl: './custom-date-range.component.html',
  styleUrls: ['./custom-date-range.component.css']
})
export class CustomDateRangeComponent implements OnInit {
  selectedStartDate: any;
  selectedEndDate: any;
  @Output() emitDates = new EventEmitter();
  @ViewChild('hideDiv', { read: ElementRef, static: true }) hideDiv: any | undefined;

  maxDate: any = new Date();
  minDate: any = new Date(
    moment()
      .subtract(90, 'days')
      .startOf('day')
      .valueOf()
  );
  constructor(private el: ElementRef) {}

  ngOnInit() {}

  onSelect(event:any) {
    this.selectedStartDate = event;
  }
  onSelect2(event:any) {
    this.selectedEndDate = event;
  }

  apply() {
    console.log(this.selectedStartDate , this.selectedEndDate)
    if (this.selectedStartDate && this.selectedEndDate) {
      this.hideDivOnButtonClick();
      const start = new Date(this.selectedStartDate).setUTCHours(0, 0, 0, 0) + 86400000;
      const end = new Date(this.selectedEndDate).setUTCHours(0, 0, 0, 0) + 86400000;
      this.emitDates.emit({
        days: 'custom',
        startDate: new Date(start).toISOString().split('T')[0],
        endDate: new Date(end).toISOString().split('T')[0]
      });
      let closePopup = this.el.nativeElement.querySelector('a');
      closePopup.classList.remove('myClass');
    }
  }
  closecustom() {
    this.hideDivOnButtonClick();
    let closePopup = this.el.nativeElement.querySelector('a');
    closePopup.classList.remove('myClass');
    // document.getElementById('myDIV').style.display = 'none';
  }

    /**@description - Method To Hide Component*/
    public hideDivOnButtonClick() {
      this.hideDiv.nativeElement.classList.add('hide-component');
      setTimeout(() => {
        this.hideDiv.nativeElement.classList.remove('hide-component');
      }, 1000);
    }
}
