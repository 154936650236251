import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MasterService } from '../../master.service';
import * as moment from 'moment';

@Component({
  selector: 'app-add-cutoff',
  templateUrl: './add-cutoff.component.html',
  styleUrls: ['./add-cutoff.component.css']
})
export class AddCutoffComponent {
  customerList: any[] = [];
  warehouseList: any[] = [];
  columnNameList:any[]=[]
  type: any;
  id: any
  selectedWarehouse:any
  clientId:any;
  customerName: any;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<Component>,
  private masterService: MasterService){
  }
 
 addCutoffForm = new FormGroup({
   customerName: new FormControl(),
   warehouseName: new FormControl(),
   gr: new FormControl(),
   gi: new FormControl()
 })
 
  ngOnInit(): void {
   const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
   this.customerList = customerList ? customerList : [];
   const warehouseList = JSON.parse(localStorage.getItem('warehouseList') || '[]');
   this.clientId = JSON.parse(localStorage.getItem('clientId')!);
   this.customerList.forEach((el:any)=>{
     if(el.id === this.clientId)
     this.customerName = el.customerName
    })
   this.warehouseList = warehouseList ? warehouseList : [];
   if (this.data) {
    this.type = this.data.type
    this.id = this.data ? this.data.id : null,
    this.addCutoffForm.patchValue(this.data.updateData)
   }
   if(this.id){
    this.selectedWarehouse = []
    this.data?.updateData?.warehouses.forEach((el:any)=>{
      this.selectedWarehouse.push(el.id)
    })
    console.log(this.selectedWarehouse)
    // this.addCutoffForm.controls.warehouseName.value =   
   }
 }  
 
 
 onSubmit(addForm: any){
   const dataToSend = {
    id: this.id ? this.id : null,
    customerName: this.customerName ? this.customerName : null,
    warehouseName: this.addCutoffForm.controls.warehouseName.value ? this.addCutoffForm.controls.warehouseName.value : null,
    gr: this.addCutoffForm.controls.gr.value ? moment(this.addCutoffForm.controls.gr.value).format('HH:mm:ss') : null,
    gi: this.addCutoffForm.controls.gi.value ? moment(this.addCutoffForm.controls.gi.value).format('HH:mm:ss') : null,
   }
   console.log(dataToSend);
   this.masterService.addUpdateCutoff(dataToSend).subscribe((res:any) => {
     if(res){
       this.dialogRef.close();
     }
   })
 }
 
  closeDialog(){
   this.dialogRef.close();
 }
 
 }
 