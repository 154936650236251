import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { SharedService } from 'src/app/shared/shared.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-customer-complaint',
  templateUrl: './customer-complaint.component.html',
  styleUrls: ['./customer-complaint.component.css']
})
export class CustomerComplaintComponent {
  minDate: any
  maxDate: any;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  pageEvent: any;
  totalRecords!: number;
  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;
  loader: boolean = false;
  warehouseList: any[] = [];
  customerList: any[] = [];
  selectedWarehouse: any;
  userRole: any;
  warehouseId: any;
  userDetails: any;
  custComplaintData: any = [];
  SelectedStartDate: any;
  SelectedEndDate: any;
  absentismHeader: any
  absentismList: any = [];
  isChecked: boolean = true;
  attritionHeader: any;
  attritionList: any = [];
  processedAbsentismList: any = [];
  confirmationDialogDisplay: boolean = false;
  progress: number = 0;
  showExcel = false;
  showReason = false;
  data: any[] = [];
  totalCalls: any;
  chunkSize: any;

  theme = 'dark'
  constructor(private fb: FormBuilder, public dialog: MatDialog, private auth: AuthService, private datePipe: DatePipe, private messageService: MessageService,
    private reportService: ReportsService, private sharedService: SharedService) {

  }

  ngOnInit(): void {
    const warehouselist = JSON.parse(localStorage.getItem('warehouseList') || '[]');
    this.warehouseList = warehouselist ? warehouselist : [];
    this.selectedWarehouse = [];

    const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
    this.customerList = customerList ? customerList : [];

    const obj = this.setFilter();
    this.getCustomerComplaint(obj, this.pageIndex, this.pageSize)

    if (this.userRole === 'ROLE_USER') {
      this.warehouseId = this.userDetails.userWarehouseMapping[0].warehouseId
      this.warehouseList.filter((el: any) => {
        if (el.id === this.warehouseId) {
          this.selectedWarehouse.push(el.plantName)
        }
      })
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }
    else {
      this.selectedWarehouse = []
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }

  }

  filterForm = this.fb.group({
    rangeDates: new FormControl(''),
    warehouse: new FormControl(),
    customer: new FormControl()
  });

  getCustomerComplaint(filter: any, pageIndex: any, pageSize: any) {
    this.reportService.getCustomerComplaint(filter, pageIndex, pageSize).subscribe((res: any) => {
      if (res != null) {
        this.loader = false
        this.length = res?.responseObject.totalElements
        this.custComplaintData = res?.responseObject.content;
        this.chunkSize = 250;
        this.totalCalls = Math.ceil(this.totalRecords / this.chunkSize);
        this.totalRecords = this.length;
      }
    })
  }

  reset() {
    this.filterForm.reset();
    const obj = this.setFilter()
    this.getCustomerComplaint(obj, this.pageIndex, this.pageSize);
    this.progress = 0
    this.showExcel = false
  }

  selectRange(event: any) {
    if (this.filterForm.value.rangeDates![0]) {
      this.minDate = this.filterForm.value.rangeDates![0]
      var futureMonth = moment(this.minDate).add(1, 'M').add(15, 'days');
      var futureMonthEnd = moment(futureMonth, 'DD-MM-YYYY').format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ (India Standard Time)');
      this.maxDate = new Date(futureMonthEnd)
    }
  }


  selectWarehouse(event: any) {
    this.selectedWarehouse = []
    if (event.value.length > 0) {
      event.value.forEach((element: any) => {
        this.selectedWarehouse.push(element.id)
      });
    }
  }

  setFilter() {
    const obj = [];
    if (this.filterForm.controls.warehouse.value && this.selectedWarehouse.length > 0) {
      obj.push({
        "key": "WAREHOUSE_ID_IN",
        "value": this.selectedWarehouse.join(',')
      })
    }
    if (this.filterForm.controls.customer.value) {
      let customerValue = this.filterForm.controls.customer.value;
      obj.push({
        "key": "CUSTOMER_ID_IN",
        "value": customerValue.id
      })
    }
    if (this.filterForm.controls.rangeDates.value) {
      if (this.filterForm.controls.rangeDates.value[0]) {
        this.SelectedStartDate = moment(this.filterForm.controls.rangeDates.value[0]).format('DD-MM-yyyy ZZ')
        obj.push({
          "key": "FROM_DATE",
          dateValue: this.SelectedStartDate ? this.SelectedStartDate : ''
        })
      }
      if (this.filterForm.controls.rangeDates.value[1]) {
        this.SelectedEndDate = moment(this.filterForm.controls.rangeDates.value[1]).format('DD-MM-yyyy ZZ')
        obj.push({
          "key": "TO_DATE",
          dateValue: this.SelectedEndDate ? this.SelectedEndDate : ''
        })
      }
    }
    return obj;
  }

  applyFilter() {
    const obj = this.setFilter();
    this.getCustomerComplaint(obj, this.pageIndex, this.pageSize)
  }

  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent, this.length, this.pageIndex, this.pageIndex)
    this.allChecked = false;
    const obj = this.setFilter()
    this.getCustomerComplaint(obj, this.pageIndex, this.pageSize)
  }

  fetchDataAndCreateExcel(type: any) {
    if (type === 'excel') {
      this.showExcel = true
      // this.showReason =false
    }
    else {
      this.showExcel = false
      // this.showReason =true
    }
    if (this.length > 50000) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Record count has surpassed the 50,000 limit. Please refine your search criteria.',
      });
      return
    }
    this.fetchDataInPages(type);
  }

  fetchDataInPages(type: any) {
    const obj = this.setFilter()
    if (this.pageIndex < this.totalCalls) {
      this.progress = this.progress === 0 ? 1 : this.progress
      this.reportService.getCustomerComplaint(obj, this.pageIndex, this.chunkSize).subscribe((res: any) => {

        this.data = this.data.concat(res?.responseObject.content);
        console.log(this.data);
        this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
        this.pageIndex++;

        if (this.pageIndex < this.totalCalls) {
          this.fetchDataInPages(type);
        } else {
          this.createExcel(type);
        }
      });
    }
  }

  createExcel(type: any) {
    if (this.data.length > 0 && type === 'excel') {

      const filteredData = [
        ['S.No', 'Date', 'Customer_Name', 'Warehouse Name', 'Ticket ID','Received date', 'First Acknow. Date',
          'Validate Status', 'Validation Response Date', 'Material type', 'Complaints Type', 'Material Received Date',
          'Originated from code', 'Originated from category', 'Originated from Location', 'Transporter Name', 'LR No',
          'Invoice No', 'Invoice Date', 'Complaint received for Item Code', 'Variance in Qty', 'Observation',
          'Approval Mail to client Sent Date', 'Approval Mail Received from client Date', 'Stock Adjustment( Sender Site)',
          'Stock Adjustment(Receiver Site)', 'Closing Date', 'Remarks'
        ]
      ];
      const filteredDataSource = this.data;

      filteredDataSource.forEach((element: any, index: number) => {
        element.customerComplainList.forEach((el:any) => {
          filteredData.push([
            index + 1,
            element.recordDate, element.customerMaster?.customerName, element.warehouseInfo?.plantName,
            el?.ticketId, el?.receivedDate, el?.firstAcknowDate, el?.validateStatus, el?.validationResponseDate,
            el?.materialType, el?.complaintsType, el?.materialReceivedDate, el?.originatedFromCode, 
            el?.originatedFromCategory, el?.originatedFromLocation, el?.transporterName, el?.lrNo,
            el?.invoiceNo, el?.invoiceDate, el?.complaintReceivedFor, el?.varianceInQty, el?.observation,
            el?.approvalMailToClientSentDate, el?.approvalMailReceivedFromClientDate, el?.stockAdjustmentSenderSite, 
            el?.stockAdjustmentReceiverSite, el?.closingDate, el?.remarks
          ]);
        });
      });
      var ws = XLSX.utils.aoa_to_sheet(filteredData);

      const wb = XLSX.utils.book_new();
      // Generate today's date in the format DD-MM-YYYY
      const todayDateTime = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
      this.progress = 0;
      this.pageIndex = 0;
      this.data = []
      this.showExcel = false
      this.showReason = false
      const filename = `Customer-complaint ${todayDateTime}.xlsx`;

      XLSX.utils.book_append_sheet(wb, ws, 'Customer-complaint');
      XLSX.writeFile(wb, filename);
    }
  }
}
