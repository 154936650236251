<div class="page-wrapper" [ngClass]="[theme === 'dark'? 'dark' : '']">
    <div class="content">
        <div class="order-details">
            <div class="content p-0">
                <div class="page-header">
                    <div class="page-title d-flex justify-content-between w-100">
                        <h4>Warehouse</h4>
                        <div class="pagination">
                            <div class="page-btn">
                                <a class="btn btn-added" (click)="addUpdateCustomer('','add')">
                                    <!-- <img src="assets/img/icons/plus.svg" alt="img" class="me-1"> -->
                                    Add Warehouse</a>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Absentisum & attrition -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top position-relative">
                            <form [formGroup]="filterForm">
                                <div class="search-set justify-content-between w-100">
                                    <div class="search-path page-header ">
                                        <a class="btn btn-cancel" (click)="reset()">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                            Reset
                                        </a>
                                        <a class="btn btn-added ms-3" (click)="op.toggle($event)" icon="pi pi-image"
                                            label="Show">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                                                </path>
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                                                </path>
                                            </svg>
                                            Filter Data</a>
                                    </div>
                                    <div class="filterCard flex justify-content-center" style="width: 35vw;">
                                        <p-overlayPanel #op [showCloseIcon]="true" [dismissable]='false'>
                                            <ng-template pTemplate="content">
                                                <div class="card">
                                                    <h4 class="filterHeader">Filter Data</h4>
                                                    <div class="row">

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Warehouse</label>
                                                                <p-multiSelect [options]="warehouseList"
                                                                    formControlName="warehouse"
                                                                    placeholder="Select a Warehouse"
                                                                    optionLabel="plantName"
                                                                    (onChange)="selectWarehouse($event)"></p-multiSelect>
                                                            </div>
                                                        </div>

                                                        <!-- <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Customer</label>
                                                                <p-dropdown formControlName="customer"
                                                                    [options]="customerList"
                                                                    placeholder="Select Customer"
                                                                    optionLabel="customerName"></p-dropdown>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="search-path page-header ">
                                                        <a class="btn btn-primary" id="filter_search"
                                                            (click)="op.toggle(false);applyFilter()">
                                                            <svg viewBox="0 0 24 24" width="18" height="18"
                                                                stroke="currentColor" stroke-width="2" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="css-i6dzq1">
                                                                <circle cx="11" cy="11" r="8"></circle>
                                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                            </svg>
                                                            Apply Filter
                                                        </a>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-overlayPanel>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="table-responsive">
                            <table class="table order-data">
                                <thead class="table-head">
                                    <tr>
                                        <ng-container>
                                            <th>Action</th>
                                            <th>Warehouse Name</th>
                                            <th>Warehouse Code</th>
                                            <th>Address</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Country</th>
                                            <th>Description</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="!loader">
                                        <tr *ngFor="let row of warehouseData">
                                            <td> 
                                                <a class="ms-3" (click)="addUpdateCustomer(row,'update')">
                                                    <svg style="width: 22px;fill: #a0a0a0;" matTooltip="Update"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                                        <path
                                                            d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z" />
                                                    </svg>
                                                </a>
                                            </td>
                                            <td>{{ row.plantName ? row.plantName : 'NA' }}</td>
                                            <td>{{ row.plantCode ? row.plantCode : 'NA' }}</td>
                                            <td>{{ row.address1 }}<span *ngIf="row.address2">,</span>{{row.address2}}</td>
                                            <td>{{ row.city ? row.city : 'NA' }}</td>
                                            <td>{{ row.state ? row.state : 'NA' }}</td>
                                            <td>{{ row.country ? row.country : 'NA'}}</td>
                                            <td>{{ row.description ? row.description : 'NA' }}</td>
                                        </tr>
                                        <tr *ngIf="warehouseData?.length == 0">
                                            <div class="no-order pt-4 pb-4">
                                                <p class="margin-0">No data found</p>
                                            </div>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="pagination search-set justify-content-end flex-end w-100">
                    <mat-paginator class="demo-paginator" (page)="pageNavigations($event)" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]" aria-label="Select page">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</div>