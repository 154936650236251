import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MasterRoutingModule } from './master-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AppMaterialModule } from '../_material/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';
import { ReasonComponent } from './components/reason/reason.component';
import { CutoffComponent } from './components/cutoff/cutoff.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalculationComponent } from './components/calculation/calculation.component';
import { UpdateCalculationComponent } from './components/update-calculation/update-calculation.component';
import { AddCalculationComponent } from './components/add-calculation/add-calculation.component';
import { CustomerComponent } from './components/customer/customer.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { AddWarehouseComponent } from './components/add-warehouse/add-warehouse.component';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { AddCutoffComponent } from './components/add-cutoff/add-cutoff.component';
import { UpdateReasonComponent } from './components/update-reason/update-reason.component';

@NgModule({
  declarations: [
    ReasonComponent,
    CutoffComponent,
    CalculationComponent,
    UpdateCalculationComponent,
    AddCalculationComponent,
    CustomerComponent,
    WarehouseComponent,
    AddWarehouseComponent,
    AddCustomerComponent,
    AddCutoffComponent,
    UpdateReasonComponent
  ],
  imports: [
    CommonModule,
    MasterRoutingModule,MultiSelectModule,
    FormsModule,
    DatePipe,
    SharedModule,AppMaterialModule,ReactiveFormsModule,OverlayPanelModule,DropdownModule
  ]
})
export class MasterModule { }
