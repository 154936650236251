import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Configuration } from 'src/app/constants/constants/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class roleService {
  BASE_DOMAIN = environment.MAIN_URL
  private activeModules: string[] = [];
  private activeSubmodules: string[] = [];

  constructor(private API_URL: Configuration,private http: HttpClient) { }


  fetchRoleList(): void {
    const url = `${environment.LOGIN_DOMAIN}${this.API_URL.CONFIG_URL.ROLE_MAPING}?platform=dashboard`;
    this.activeModules = []
    this.activeSubmodules = []
    this.http.get<any>(url).subscribe((res: any) => {
        if (res?.responseObject?.modules) {
          const modules = res.responseObject.modules;
          modules.forEach((element: any) => {
            if (
              element.moduleName === 'Settings' ||
              element.moduleName === 'Update' ||
              element.moduleName === 'Utilities' ||
              element.moduleName === 'Reports'
              ) {
              this.activeModules.push(element.moduleName);
            }
            element.subModules.forEach((item: any) => {
              if (
                item.subModuleName === 'Upload' ||
                item.subModuleName === 'Calendar' ||
                item.subModuleName === 'Order Update' ||
                item.subModuleName === 'GRN Update' ||
                item.subModuleName === 'Order Line' ||
                item.subModuleName === 'Users' || 
                item.subModuleName === 'Notifications' ||
                item.subModuleName === 'KPI' ||
                item.subModuleName === 'FE Audit' ||
                item.subModuleName === 'Absentism & Attrition' ||
                item.subModuleName === 'Safety & Nearmiss' ||
                item.subModuleName === 'Safety Incident Register' ||
                item.subModuleName === 'Customer Complaint' ||
                item.subModuleName === 'Audit Report' 

              ) {
                this.activeSubmodules.push(item.subModuleName);
              }
            });
          });
        }
      },
      (error) => {
        console.error('Failed to fetch role list', error);
      }
    );
  }

  isActiveModule(moduleName: string): boolean {
    // console.log(moduleName)
    return this.activeModules.includes(moduleName);
  }

  isActiveSubmodule(submoduleName: string): boolean {
    // console.log('Orders Report',submoduleName)
    return this.activeSubmodules.includes(submoduleName);
  }
}
