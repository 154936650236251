import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateCalculationComponent } from '../update-calculation/update-calculation.component';
import { MasterService } from '../../master.service';

interface FileType {
  type: string;
}
interface Type {
  type: String
}
@Component({
  selector: 'app-add-calculation',
  templateUrl: './add-calculation.component.html',
  styleUrls: ['./add-calculation.component.css']
})
export class AddCalculationComponent {
//  addCalculationForm: FormGroup | any
 fileType: FileType | any;
 type: Type[] | any;
 customerList: any[] = [];
 columnNameList:any[]=[]
 constructor( @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<AddCalculationComponent>,
 private masterService: MasterService){
 }

addCalculationForm = new FormGroup({
  fileType: new FormControl(''),
  customerName: new FormControl(),
  formula: new FormControl(''),
  tblColumnName: new FormControl(),
})

 ngOnInit(): void {
  const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
  this.customerList = customerList ? customerList : [];
  this.getColumnNameList()
  this.type = [
    { label: 'Default', value: 'default' },
    { label: 'User', value: 'user' }
  ];

  this.fileType = [
    { label: 'GR', value: 'GR' },
    { label: 'GI', value: 'GI' },
  ]


  if (this.data['component'] === 'addCalculation') {
   
  }
}  


onSubmit(addForm: any){
  const addObj = {
    "id": null,
    "logicType": "user_logic",
    "fileType": this.addCalculationForm.controls.fileType.value,
    "clientName": this.addCalculationForm.controls.customerName.value ? this.addCalculationForm.controls.customerName.value.charAt(0).toUpperCase() + this.addCalculationForm.controls.customerName.value.slice(1) : null,
    "tblColumnName": this.addCalculationForm.controls.tblColumnName.value,
    "formula": this.addCalculationForm.controls.formula.value,
    "status": 1
  }

  console.log(addObj);
  this.masterService.addCalculation(addObj).subscribe((res:any) => {
    if(res){
      this.dialogRef.close();
    }
  })
}

 closeDialog(){
  this.dialogRef.close();
}

getColumnNameList(){
  this.masterService.getColumnNameList().subscribe((res:any)=>{
    this.columnNameList = res.responseObject.map((el:any) => ({
      columnName : el
    }))
    console.log(this.columnNameList)
  })
}
}
