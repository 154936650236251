<div class="date-range-row" #hideDiv>
    <h2>Custom date range</h2>
    <div class="start-date">
        <div class="input-field-group">
            <input placeholder="Start Date" type="text" [value]="selectedStartDate | date: 'dd/MM/yyyy'"
                [disabled]="true" required />
        </div>
        <mat-calendar [maxDate]="maxDate" [minDate]="minDate" [selected]="selectedStartDate"
            (selectedChange)="onSelectFromDate($event)"></mat-calendar>
    </div>
    <div class="end-date">
        <div class="input-field-group">
            <input placeholder="End Date" type="text" [value]="selectedEndDate | date: 'dd/MM/yyyy'" [disabled]="true"
                required />
        </div>
        <mat-calendar [maxDate]="maxDate" [minDate]="minDate" [selected]="selectedEndDate"
            (selectedChange)="onSelectToDate($event)">
        </mat-calendar>
    </div>
    <mat-error class="error-message" *ngIf="notValid">
        {{ accountslug === 'eshop' ? 'Please select only 31 days' : 'Please select only 180 days' }}
    </mat-error>
    <mat-error class="error-message" *ngIf="notValidStartDate">
        Please select valid date
    </mat-error>
    <div class="action-buttons">
        <a class="btn no-icon" (click)="hideDivOnButtonClick()"> Cancel </a>
        <ng-container *ngIf="notValid || notValidStartDate; else showactivebutton">
            <a class="btn primary-btn no-icon" [class.disabled]="notValid || notValidStartDate">
                Done
            </a>
        </ng-container>
        <ng-template #showactivebutton>
            <a class="btn primary-btn no-icon" [class.disabled]="notValid || notValidStartDate"
                (click)="onSubmitDates()" *ngIf="!notValid || !notValidStartDate">
                Done
            </a>
        </ng-template>
    </div>
</div>