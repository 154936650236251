import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent {
  customerList: any[] = [];
  warehouseList: any[] = [];
  columnNameList:any[]=[]
  type: any;
  id: any
  selectedWarehouse:any
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<Component>,
  private masterService: MasterService){
  }
 
 addCustomerForm = new FormGroup({
   customerName: new FormControl(),
   warehouseName: new FormControl(),
 })
 
  ngOnInit(): void {
   const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
   this.customerList = customerList ? customerList : [];
   const warehouseList = JSON.parse(localStorage.getItem('warehouseList') || '[]');
   this.warehouseList = warehouseList ? warehouseList : [];
   if (this.data) {
    this.type = this.data.type
    this.id = this.data ? this.data.id : null,
    this.addCustomerForm.patchValue(this.data.updateData)
   }
   if(this.id){
    this.selectedWarehouse = []
    this.data?.updateData?.warehouses.forEach((el:any)=>{
      this.selectedWarehouse.push(el.id)
    })
    console.log(this.selectedWarehouse)
    // this.addCustomerForm.controls.warehouseName.value =   
   }
 }  
 
 
 onSubmit(addForm: any){
  const sendWarehouse = this.selectedWarehouse.map((el:any) => ({
    id : el
  }))
   const dataToSend = {
    id: this.id ? this.id : null,
    customerName: this.addCustomerForm.controls.customerName.value ? this.addCustomerForm.controls.customerName.value : null,
    warehouses: sendWarehouse
   }
   console.log(dataToSend);
   this.masterService.addUpdateCustomer(dataToSend).subscribe((res:any) => {
     if(res){
       this.dialogRef.close();
     }
   })
 }
 
  closeDialog(){
   this.dialogRef.close();
 }
 
 }
 