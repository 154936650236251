import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Configuration } from 'src/app/constants/constants/config';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private api : ApiService,private API_URL: Configuration,private http: HttpClient) { }

  getAllAbsentism(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_ALL_ABSENTISM}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  getAllSafetyAndNearmiss(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_SAFETY_AND_NEARMISS}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  getSafetyIncidentRegister(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_SAFETY_INCIDENT_REGISTER}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  getCustomerComplaint(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_CUSTOMER_COMPLAINT}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  getAuditReport(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_AUDIT_REPORT}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }
}
