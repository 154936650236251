import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReasonComponent } from './components/reason/reason.component';
import { CutoffComponent } from './components/cutoff/cutoff.component';
import { CalculationComponent } from './components/calculation/calculation.component';
import { CustomerComponent } from './components/customer/customer.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
    data: { title: 'Dashboard - Order' }
  },
  {
    path: 'reason',
    component: ReasonComponent,
    data: {title : 'Dashboard - Reason'}
  },
  {
    path: 'cutoff',
    component: CutoffComponent,
    data: {title : 'Dashboard - Cutoff'}
  },
  {
    path: 'calculation',
    component: CalculationComponent,
    data: {title: 'Dashboard - Calculation'}
  },
  {
    path: 'customer',
    component: CustomerComponent,
    data: {title: 'Dashboard - Customer'}
  },
  {
    path: 'warehouse',
    component: WarehouseComponent,
    data: {title: 'Dashboard - Customer'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterRoutingModule { }
