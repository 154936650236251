<div class="page-wrapper" [ngClass]="[theme === 'dark'? 'dark' : '']">
    <div class="content">
        <div class="order-details">
            <div class="content p-0">
                <div class="page-header">
                    <div class="page-title d-flex justify-content-between w-100">
                        <h4>Safety Incident Register</h4>
                        <div class="pagination">
                            <button *ngIf="!showExcel" class="Printall-label btn btn-submit"
                                (click)="fetchDataAndCreateExcel('excel')"
                                [disabled]="!filterForm.controls.rangeDates.value">
                                <svg [ngStyle]="{fill: !filterForm.controls.rangeDates.value ? 'grey' : ''}"
                                    viewBox="0 0 24 24" width="18" height="18" stroke="#fff" stroke-width="2"
                                    fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                    <polyline points="6 9 6 2 18 2 18 9"></polyline>
                                    <path
                                        d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2">
                                    </path>
                                    <rect x="6" y="14" width="12" height="8"></rect>
                                </svg> Excel
                            </button>
                            <p-progressBar *ngIf="showExcel && !showReason && progress>0" [value]="progress"
                                [style]="{width:'150px',height: '35px'}" />

                            <!-- <button *ngIf="!showReason" class="Printall-label btn btn-submit ms-3" (click)="fetchDataAndCreateExcel('reason')"  [disabled]="!FilterForm.controls.rangeDates.value">
                                <svg [ngStyle]="{fill: !FilterForm.controls.rangeDates.value ? 'grey' : ''}" viewBox="0 0 24 24" width="18" height="18" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>  Reason Excel
                            </button>	 -->
                            <p-progressBar *ngIf="!showExcel && showReason && progress>0" [value]="progress"
                                [style]="{width:'150px',height: '35px',margin: '0 0 0 15px'}" />
                        </div>
                    </div>
                </div>


                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top position-relative">
                            <form [formGroup]="filterForm">
                                <div class="search-set justify-content-between w-100">
                                    <div class="search-path page-header ">
                                        <a class="btn btn-cancel" (click)="reset()">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                            Reset
                                        </a>
                                        <a class="btn btn-added ms-3" (click)="op.toggle($event)" icon="pi pi-image"
                                            label="Show">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                                                </path>
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                                                </path>
                                            </svg>
                                            Filter Data</a>
                                    </div>
                                    <div class="filterCard flex justify-content-center" style="width: 35vw;">
                                        <p-overlayPanel #op [showCloseIcon]="true" [dismissable]='false'>
                                            <ng-template pTemplate="content">
                                                <div class="card">
                                                    <h4 class="filterHeader">Filter Data</h4>
                                                    <div class="row">

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Warehouse</label>
                                                                <p-multiSelect [options]="warehouseList"
                                                                    formControlName="warehouse"
                                                                    placeholder="Select a Warehouse"
                                                                    optionLabel="plantName"
                                                                    (onChange)="selectWarehouse($event)"></p-multiSelect>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Customer</label>
                                                                <p-dropdown formControlName="customer" [options]="customerList"
                                                                    placeholder="Select Customer"
                                                                    optionLabel="customerName" ></p-dropdown>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Date Range</label>
                                                                <p-calendar formControlName="rangeDates"
                                                                    selectionMode="range"
                                                                    placeholder="Start Date - End Date"
                                                                    (onSelect)="selectRange($event)"></p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="search-path page-header ">
                                                        <a class="btn btn-primary" id="filter_search"
                                                            (click)="op.toggle(false);applyFilter()">
                                                            <svg viewBox="0 0 24 24" width="18" height="18"
                                                                stroke="currentColor" stroke-width="2" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="css-i6dzq1">
                                                                <circle cx="11" cy="11" r="8"></circle>
                                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                            </svg>
                                                            Apply Filter
                                                        </a>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-overlayPanel>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="table-responsive">
                            <table class="table order-data">
                                <thead class="table-head">
                                    <tr>

                                        <ng-container>
                                            <th>Action</th>
                                            <th>Date</th>
                                            <th>Customer Name</th>
                                            <th>Warehouse Name</th>
                                            <th>Type Of Incident</th>
                                            <th>Brief Description</th>
                                            <th>Name of Person/s <br>Impacted/Equipment impacted</th>
                                            <th>Classification Of Injury</th>
                                            <th>Nature of Injury</th>
                                            <th>Injured Body Part</th>
                                            <th>Reported By</th>
                                            <th>Man Hours Lost</th>
                                            <!-- <th>Safety</th> -->
                                            <th>Proof</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of safetyData">

                                        <td><a [routerLink]="['/setting/kpi']"
                                            [queryParams]="{recordDate: row.recordDate, customerId: row.customerMaster?.id, warehouseId: row.warehouseInfo?.id, safetyIncident: row?.totalIncident}" class="me-3 d-flex justify-content-center">
                                            <img matTooltip="Edit" src="assets/img/icons/edit.svg" alt="img">
                                          </a></td>
                                        <td>{{ row.recordDate }}</td>
                                        <td>{{ row.customerMaster?.customerName }}</td>
                                        <td>{{ row.warehouseInfo?.plantName }}</td>
                                        <td><ng-container *ngFor="let item of row.totalIncidentList">
                                                {{item?.typeOfIncident?.value}} <br>
                                            </ng-container>
                                        </td>
                                        <td><ng-container *ngFor="let item of row.totalIncidentList">
                                                {{item?.briefDescription}} <br>
                                            </ng-container></td>
                                        <td><ng-container *ngFor="let item of row.totalIncidentList">
                                                {{item?.nameOfPersonImpactedEquipment}} <br>
                                            </ng-container></td>
                                        <td><ng-container *ngFor="let item of row.totalIncidentList">
                                                {{item?.classificationOfInjury?.value}} <br>
                                            </ng-container></td>
                                        <td><ng-container *ngFor="let item of row.totalIncidentList">
                                                {{item?.natureOfInjury?.value}} <br>
                                            </ng-container></td>
                                        <td><ng-container *ngFor="let item of row.totalIncidentList">
                                                {{item?.injuredBodyPart?.value}} <br>
                                            </ng-container>
                                        </td>
                                        <td><ng-container *ngFor="let item of row.totalIncidentList">
                                                {{item?.reportedBy}} <br>
                                            </ng-container></td>
                                        <td><ng-container *ngFor="let item of row.totalIncidentList">
                                                {{item?.manHourLost}} <br>
                                            </ng-container></td>

                                        <td>
                                            <ng-container *ngFor="let item of row.totalIncidentList">
                                                <ng-container *ngIf="item?.uploadProof">
                                                    <!-- <span >{{getFilename(item?.uploadProof)}}</span> -->
                                                    <span class="ms-3" *ngIf="item.uploadProof !== ''" matTooltip="{{ getFilename(item?.uploadProof) }}"><a download
                                                        [href]="'https://dev.apollosupplychain.com/' + replaceURL(item?.uploadProof)">
                                                        <svg width="24" height="24" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M11.445 16.6875H6.55499C2.87249 16.6875 1.29749 15.1125 1.29749 11.43V11.3325C1.29749 8.00246 2.60999 6.39746 5.54999 6.11996C5.84999 6.09746 6.13499 6.32246 6.16499 6.62996C6.19499 6.93746 5.96999 7.21496 5.65499 7.24496C3.29999 7.46246 2.42249 8.57246 2.42249 11.34V11.4375C2.42249 14.49 3.50249 15.57 6.55499 15.57H11.445C14.4975 15.57 15.5775 14.49 15.5775 11.4375V11.34C15.5775 8.55746 14.685 7.44746 12.285 7.24496C11.9775 7.21496 11.745 6.94496 11.775 6.63746C11.805 6.32996 12.0675 6.09746 12.3825 6.12746C15.3675 6.38246 16.7025 7.99496 16.7025 11.3475V11.445C16.7025 15.1125 15.1275 16.6875 11.445 16.6875Z"
                                                                fill="#6E6B7B" />
                                                            <path
                                                                d="M9 11.7225C8.6925 11.7225 8.4375 11.4675 8.4375 11.16V1.5C8.4375 1.1925 8.6925 0.9375 9 0.9375C9.3075 0.9375 9.5625 1.1925 9.5625 1.5V11.16C9.5625 11.475 9.3075 11.7225 9 11.7225Z"
                                                                fill="#6E6B7B" />
                                                            <path
                                                                d="M9.00001 12.5624C8.85751 12.5624 8.71501 12.5099 8.60251 12.3974L6.09 9.88488C5.8725 9.66738 5.8725 9.30738 6.09 9.08988C6.3075 8.87238 6.66751 8.87238 6.88501 9.08988L9.00001 11.2049L11.115 9.08988C11.3325 8.87238 11.6925 8.87238 11.91 9.08988C12.1275 9.30738 12.1275 9.66738 11.91 9.88488L9.3975 12.3974C9.285 12.5099 9.14251 12.5624 9.00001 12.5624Z"
                                                                fill="#6E6B7B" />
                                                        </svg>
                                                    </a></span>
                                                </ng-container>
                                                <br>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <tr *ngIf="safetyData?.length == 0">
                                        <div class="no-order pt-4 pb-4">
                                            <p class="margin-0">No data found</p>
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="pagination search-set justify-content-end flex-end w-100">
                    <mat-paginator class="demo-paginator" (page)="pageNavigations($event)" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]" aria-label="Select page">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</div>