import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
  @Input() display: boolean = false;
  @Input() message: string = 'Are you sure you want to update?';
  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  confirmAction(choice: boolean) {
    this.confirm.emit(choice);
    this.display = false;
  }
}