<div class="">
    <div class="content">
        <div class="order-details">
            <div class=" p-0">
                <div class="page-header">
                    <div class="page-title" style="margin-left: -10px;">
                        <h4>Update Calculation</h4>
                    </div>
                    <div class="close">
                        <a mat-dialog-close="">
                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="#333" stroke-width="2" fill="none"
                                stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </a>
                    </div>
                </div>

                <div class="card border-none">
                    <div class="card-body p-0">
                        <form [formGroup]="updateForm" (ngSubmit)="onSubmit(updateForm)">
                            <div class="row mt-2">
                                <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                        <label>Report Name</label>
                                        <input formControlName="fileType" type="text">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                        <label>Schema Column Name</label>
                                        <input formControlName="tblColumnName" type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                        <label>Client Column Name</label>
                                        <input formControlName="clientColumnName" type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                        <label>Default Formula</label>
                                        <textarea type="text" rows="2" formControlName="defaultFormula"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                        <label class="col-md-12">
                                            <span class="col-md-4">Update Formula</span>
                                            <span class="col-md-4 offset-md-4">
                                                <div class="custom-checkbox">
                                                    <input type="checkbox" id="customCheckbox"
                                                        (change)="setDefaultFormulae($event)">
                                                    <label for="customCheckbox"></label>
                                                </div>

                                                <!-- <p-checkbox formControlName="isDefaultChecked" (onChange)="setDefaultFormulae($event)" inputId="default"></p-checkbox> -->
                                                <label style="display: contents;" for="default"> Copy default</label>
                                            </span>
                                        </label>
                                        <textarea type="text" rows="2" formControlName="formula"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12" *ngIf="calculationReport">
                                    <div class="form-group">
                                        <label>Result</label>
                                        {{calculationReport ? calculationReport[data.updateData.tblColumnName] : null}}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <span><span class="font-weight-bold text-danger">*Schema Column Names:</span></span>

                                <div *ngIf="updateForm.get('fileType')?.value === 'GR'">
                                    <p>p_PrimaryKey, p_TimeOfEntry, p_EntryDate, p_Warehouse, p_Customer, p_clientId,
                                        p_PstngDate, p_CutoffTime, p_GrnDay, p_ArriveType, p_ExpectedGrnDate,
                                        p_AgeingGrn, p_CategoryGrn</p>
                                </div>

                                <div *ngIf="updateForm.get('fileType')?.value === 'GI'">
                                    <p>p_PrimaryKey, p_DocumentDate, p_ShippingType, p_Warehouse, p_Customer, p_clientId,
                                        p_GateOutDate, p_DispatchBillingDocument, p_Material, p_deliveryTime, p_CutoffTime,
                                        p_OrderDay, p_DoTime, p_OrderReceived, p_ExpectedGiDate, p_ageingObnd, p_categoryObnd</p>
                                </div>
                            </div>
                            <div class="row mt-2 mb-3">
                                <div class="col-lg-12">
                                    <button class="btn btn-submit me-2" type="">Submit
                                        <div class="loader">
                                            <mat-spinner [diameter]="25" [strokeWidth]="2"
                                                class="centre-align"></mat-spinner>
                                        </div>
                                    </button>
                                    <a href="javascript:void(0);" class="btn btn-submit me-2"
                                        (click)="testCalculationProcess()">Test Calculation</a>
                                    <!-- <button class="btn btn-submit me-2" >Test Calculation
                                </button> -->
                                    <a href="javascript:void(0);" class="btn btn-cancel"
                                        (click)="closeDialog()">Cancel</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>