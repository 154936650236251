import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-add-warehouse',
  templateUrl: './add-warehouse.component.html',
  styleUrls: ['./add-warehouse.component.css']
})
export class AddWarehouseComponent {
  customerList: any[] = [];
  warehouseList: any[] = [];
  columnNameList:any[]=[]
  type: any;
  clientId: any
  id:any
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<Component>,
  private masterService: MasterService){
  }
 
 addCustomerForm = new FormGroup({
  plantCode: new FormControl(),
  plantName: new FormControl(),
  description: new FormControl(),
  address1: new FormControl(),
  address2: new FormControl(),
  city: new FormControl(),
  state: new FormControl(),
  country: new FormControl(),
  pincode: new FormControl(),
  customers: new FormControl([])
 })
 
  ngOnInit(): void {
   const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
   this.customerList = customerList ? customerList : [];
   const warehouseList = JSON.parse(localStorage.getItem('warehouseList') || '[]');
   this.clientId = JSON.parse(localStorage.getItem('clientId')!);
   this.warehouseList = warehouseList ? warehouseList : [];
   if (this.data) {
    this.type = this.data.type,
    this.id = this.data ? this.data.id : null 
    this.addCustomerForm.patchValue(this.data.updateData)
   }
 }  
 
 onSubmit(addForm: any){
  
   const dataToSend = {
    id: this.id ? this.id : null,
    plantCode: this.addCustomerForm.controls.plantCode.value ? this.addCustomerForm.controls.plantCode.value : null,
    plantName: this.addCustomerForm.controls.plantName.value ? this.addCustomerForm.controls.plantName.value : null,
    description: this.addCustomerForm.controls.description.value ? this.addCustomerForm.controls.description.value : null,
    address1: this.addCustomerForm.controls.address1.value ? this.addCustomerForm.controls.address1.value : null,
    address2: this.addCustomerForm.controls.address2.value ? this.addCustomerForm.controls.address2.value : null,
    city: this.addCustomerForm.controls.city.value ? this.addCustomerForm.controls.city.value : null,
    state: this.addCustomerForm.controls.state.value ? this.addCustomerForm.controls.state.value : null,
    country: this.addCustomerForm.controls.country.value ? this.addCustomerForm.controls.country.value : null,
    pincode: this.addCustomerForm.controls.pincode.value ? this.addCustomerForm.controls.pincode.value : null,
    status: 1,
    // customers: [{
    //   id: this.clientId ? this.clientId : null
    // }],
   }
   console.log(dataToSend);
   this.masterService.addUpdateWarehouse(dataToSend).subscribe((res:any) => {
     if(res){
       this.dialogRef.close();
     }
   })
 }
 
  closeDialog(){
   this.dialogRef.close();
 }

 
 
 }