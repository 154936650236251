<div class="">
    <div class="content">
      <div class="order-details">
        <div class=" p-0">
          <div class="page-header">
            <div class="page-title" style="margin-left: -10px;">
              <h4>
                <span>
                    {{type === 'add' ? 'Add' : 'Update'}} 
                </span>
                Warehouse</h4>
            </div>
            <div class="close">
              <a  mat-dialog-close="" >
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="#333" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
              </a>
            </div>
            </div>

            <div class="card border-none custom-card">
                <div class="card-body p-0">
                    <form [formGroup]="addCustomerForm" (ngSubmit)="onSubmit(addCustomerForm)">
                        <div class="row mt-2">
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>Warehouse Name</label>
                                    <input type="text" class="form-control" formControlName="plantName">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>Warehouse Code</label>
                                    <input type="text" class="form-control" formControlName="plantCode">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>Description</label>
                                    <input type="text" class="form-control" formControlName="description">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>Address 1</label>
                                    <input type="text" class="form-control" formControlName="address1">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>Address 2</label>
                                    <input type="text" class="form-control" formControlName="address2">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>City</label>
                                    <input type="text" class="form-control" formControlName="city">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>State</label>
                                    <input type="text" class="form-control" formControlName="state">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>Country</label>
                                    <input type="text" class="form-control" formControlName="country">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>Pincode</label>
                                    <input type="text" class="form-control" formControlName="pincode">
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>Select Customer</label>
                                    <p-multiSelect formControlName="customers"
                                        [options]="customerList"
                                        appendTo="body"
                                        placeholder="Select Customer"
                                        optionValue="id"
                                        optionLabel="customerName"></p-multiSelect>
                                </div>
                            </div> -->
                        </div>
                        <div class="row mt-2 mb-3">
                            <div class="col-lg-12">
                                <button class="btn btn-submit me-2" type="submit">Submit
                                    <div class="loader"> 
                                        <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                                    </div>
                                </button>
                                  <a href="javascript:void(0);" class="btn btn-cancel"  (click)="closeDialog()">Cancel</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>