import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { SharedService } from 'src/app/shared/shared.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.css']
})
export class AuditReportComponent {
  minDate: any
  maxDate: any;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  pageEvent: any;
  totalRecords!: number;
  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;
  loader: boolean = false;
  warehouseList: any[] = [];
  customerList: any[] = [];
  selectedWarehouse: any;
  userRole: any;
  warehouseId: any;
  userDetails: any;
  auditData: any = [];
  SelectedStartDate: any;
  SelectedEndDate: any;
  absentismHeader: any
  absentismList: any = [];
  isChecked: boolean = true;
  attritionHeader: any;
  attritionList: any = [];
  processedAbsentismList: any = [];
  confirmationDialogDisplay: boolean = false;
  progress: number = 0;
  showExcel = false;
  showReason = false;
  data: any[] = [];
  totalCalls: any;
  chunkSize: any;
  theme = 'dark'

  auditTypeOptions = [
    { label: 'Putaway', value: 'Putaway' },
    { label: 'Picking', value: 'Picking' },
    { label: 'Cycle Count', value: 'Cycle Count' },
    { label: 'Empty Bin', value: 'Empty Bin' }
  ];

  putawayPickingCycleCountColumns: string[] = [
    'Action', 'Date', 'Customer Name', 'Warehouse Name', 'Audit Type', 'Total Completed(Bin)', 'Total Completed(Qty)', 'Attempt for Audit(Bin)', 'Attempt for Audit(Qty)',
    'Correct(Bin)', 'Correct(Qty)', 'Discrepancy(Bin)', 'Discrepancy(Qty)', 'Bin Accuracy %', 'Stock Accuracy %', 'Attempt Bin %', 'Attempt Qty %',
    'Remarks'
  ];

  cycleCountColumns: string[] = [
    'Action', 'Date', 'Customer Name', 'Warehouse Name', 'Audit Type', 'Total Cycle count Planned(Bin)', 'Total Cycle count Planned(Qty)', 'Attempt for Audit(Bin)', 'Attempt for Audit(Qty)',
    'Correct(Bin)', 'Correct(Qty)', 'Discrepancy(Bin)', 'Discrepancy(Qty)', 'Bin Accuracy %', 'Stock Accuracy %', 'Attempt Bin %', 'Attempt Qty %',
    'Remarks'
  ];

  emptyBinColumns: string[] = [
    'Action', 'Date', 'Customer Name', 'Warehouse Name', 'Audit Type', 'Total Empty locations(Bin)', 'Attempt for Audit(Bin)', 'Correct(Bin)', 'Discrepancy(Bin)',
    'Discrepancy(Qty)', 'Bin Accuracy %', 'Attempt Bin %', 'Remarks'
  ];

  constructor(private fb: FormBuilder, public dialog: MatDialog, private auth: AuthService, private datePipe: DatePipe, private messageService: MessageService,
    private reportService: ReportsService, private sharedService: SharedService) {

  }

  ngOnInit(): void {
    this.absentismHeader = localStorage.getItem('absentismList');
    this.absentismList = JSON.parse(this.absentismHeader);

    this.attritionHeader = localStorage.getItem('attritionList');
    this.attritionList = JSON.parse(this.attritionHeader);

    const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
    this.customerList = customerList ? customerList : [];

    console.log(this.processedAbsentismList);
    const obj = this.setFilter();
    this.getAuditReport(obj, this.pageIndex, this.pageSize);
    const warehouselist = JSON.parse(localStorage.getItem('warehouseList') || '[]');
    this.warehouseList = warehouselist ? warehouselist : [];
    this.selectedWarehouse = []
    if (this.userRole === 'ROLE_USER') {
      this.warehouseId = this.userDetails.userWarehouseMapping[0].warehouseId
      this.warehouseList.filter((el: any) => {
        if (el.id === this.warehouseId) {
          this.selectedWarehouse.push(el.plantName)
        }
      })
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }
    else {
      this.selectedWarehouse = []
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }

  }

  filterForm = this.fb.group({
    rangeDates: new FormControl(''),
    warehouse: new FormControl(),
    customer: new FormControl(),
    auditType: ('Putaway')
  });

  getAuditReport(filter: any, pageIndex: any, pageSize: any) {
    this.reportService.getAuditReport(filter, pageIndex, pageSize).subscribe((res: any) => {
      if (res != null) {
        this.loader = false
        this.length = res?.responseObject.totalElements
        this.auditData = res?.responseObject.content;
        this.chunkSize = 250;
        this.totalCalls = Math.ceil(this.totalRecords / this.chunkSize);
        this.totalRecords = this.length;
      }
    })
  }

  reset() {
    this.filterForm.reset({ auditType: 'Putaway' });
    const obj = this.setFilter()
    this.getAuditReport(obj, this.pageIndex, this.pageSize);
    this.progress = 0
    this.showExcel = false
  }

  selectRange(event: any) {
    if (this.filterForm.value.rangeDates![0]) {
      this.minDate = this.filterForm.value.rangeDates![0]
      var futureMonth = moment(this.minDate).add(1, 'M').add(15, 'days');
      var futureMonthEnd = moment(futureMonth, 'DD-MM-YYYY').format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ (India Standard Time)');
      this.maxDate = new Date(futureMonthEnd)
    }
  }


  selectWarehouse(event: any) {
    this.selectedWarehouse = []
    if (event.value.length > 0) {
      event.value.forEach((element: any) => {
        this.selectedWarehouse.push(element.id)
      });
    }
  }

  setFilter() {
    const obj = [];
    if (this.filterForm.controls.warehouse.value && this.selectedWarehouse.length > 0) {
      obj.push({
        "key": "WAREHOUSE_ID_IN",
        "value": this.selectedWarehouse.join(',')
      })
    }
    if (this.filterForm.controls.customer.value) {
      let customerValue = this.filterForm.controls.customer.value;
      obj.push({
        "key": "CUSTOMER_ID_IN",
        "value": customerValue.id
      })
    }
    if (this.filterForm.controls.rangeDates.value) {
      if (this.filterForm.controls.rangeDates.value[0]) {
        this.SelectedStartDate = moment(this.filterForm.controls.rangeDates.value[0]).format('DD-MM-yyyy ZZ')
        obj.push({
          "key": "FROM_DATE",
          dateValue: this.SelectedStartDate ? this.SelectedStartDate : ''
        })
      }
      if (this.filterForm.controls.rangeDates.value[1]) {
        this.SelectedEndDate = moment(this.filterForm.controls.rangeDates.value[1]).format('DD-MM-yyyy ZZ')
        obj.push({
          "key": "TO_DATE",
          dateValue: this.SelectedEndDate ? this.SelectedEndDate : ''
        })
      }
    }
    return obj;
  }

  applyFilter() {
    const obj = this.setFilter();
    this.getAuditReport(obj, this.pageIndex, this.pageSize);
  }

  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent, this.length, this.pageIndex, this.pageIndex)
    this.allChecked = false;
    const obj = this.setFilter()
    this.getAuditReport(obj, this.pageIndex, this.pageSize);
  }

  fetchDataAndCreateExcel(type: any) {
    if (type === 'excel') {
      this.showExcel = true
      // this.showReason =false
    }
    else {
      this.showExcel = false
      // this.showReason =true
    }
    if (this.length > 50000) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Record count has surpassed the 50,000 limit. Please refine your search criteria.',
      });
      return
    }
    this.fetchDataInPages(type);
  }

  fetchDataInPages(type: any) {
    const obj = this.setFilter()
    if (this.pageIndex < this.totalCalls) {
      this.progress = this.progress === 0 ? 1 : this.progress
      this.reportService.getAuditReport(obj, this.pageIndex, this.chunkSize).subscribe((res: any) => {
        if (res?.responseObject?.content?.length > 0) {
          this.data = this.data.concat(res?.responseObject.content);
          console.log(this.data);
          this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
          this.pageIndex++;

          if (this.pageIndex < this.totalCalls) {
            this.fetchDataInPages(type);
          } else {
            this.createExcel(type, this.filterForm.value.auditType);
          }
        }
        else {
          this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'No data found',
          });
          return
        }
      });
    }
  }

  createExcel(type: any, auditType: any) {
    if (this.data.length > 0 && type === 'excel') {

      let headers: string[] = [];

      switch (auditType) {
        case 'Putaway':
        case 'Picking':
          headers = this.putawayPickingCycleCountColumns;
          break;

        case 'Cycle Count':
          headers = this.cycleCountColumns
          break;
        case 'Empty Bin':
          headers = this.emptyBinColumns;
          break;
      }

      const filteredData = [headers];
      const filteredDataSource = this.data;

      switch (auditType) {
        case 'Putaway':
          filteredDataSource.forEach((element: any, index: number) => {
            filteredData.push([
              index + 1, element.recordDate, element.customerMaster?.customerName, element.warehouseInfo?.plantName, this.filterForm.value.auditType,
              element.putawayAudit?.completedActionQty, element.putawayAudit?.completedActionQty,
              element.putawayAudit?.attemptBinCnt, element.putawayAudit?.attemptQtyCnt, element.putawayAudit?.correctBin,
              element.putawayAudit?.correctQty, element.putawayAudit?.discrepancyBin, element.putawayAudit?.discrepancyQty,
              element.putawayAudit?.binAccuracyPercentage, element.putawayAudit?.stockAccuracyPercentage,
              element.putawayAudit?.attemptBinPercentage, element.putawayAudit?.attemptQtyPercentage, element.putawayAudit?.remarks
            ])
          });
          break;

        case 'Picking':
          filteredDataSource.forEach((element: any, index: number) => {
            filteredData.push([
              index + 1, element.recordDate, element.customerMaster?.customerName, element.warehouseInfo?.plantName, this.filterForm.value.auditType,
              element.pickListAudit?.completedActionQty, element.pickListAudit?.completedActionQty,
              element.pickListAudit?.attemptBinCnt, element.pickListAudit?.attemptQtyCnt, element.pickListAudit?.correctBin,
              element.pickListAudit?.correctQty, element.pickListAudit?.discrepancyBin, element.pickListAudit?.discrepancyQty,
              element.pickListAudit?.binAccuracyPercentage, element.pickListAudit?.stockAccuracyPercentage,
              element.pickListAudit?.attemptBinPercentage, element.pickListAudit?.attemptQtyPercentage, element.pickListAudit?.remarks
            ])
          });
          break;

        case 'Cycle Count':
          filteredDataSource.forEach((element: any, index: number) => {
            filteredData.push([
              index + 1, element.recordDate, element.customerMaster?.customerName, element.warehouseInfo?.plantName, this.filterForm.value.auditType,
              element.cycleAudit?.plannedBinCnt, element.cycleAudit?.plannedQtyCnt,
              element.cycleAudit?.attemptBinCnt, element.cycleAudit?.attemptQtyCnt, element.cycleAudit?.correctBin,
              element.cycleAudit?.correctQty, element.cycleAudit?.discrepancyBin, element.cycleAudit?.discrepancyQty,
              element.cycleAudit?.binAccuracyPercentage, element.cycleAudit?.stockAccuracyPercentage,
              element.cycleAudit?.attemptBinPercentage, element.cycleAudit?.attemptQtyPercentage, element.cycleAudit?.remarks
            ])
          });
          break;

        case 'Empty Bin':
          filteredDataSource.forEach((element: any, index: number) => {
            filteredData.push([
              index + 1, element.recordDate, element.customerMaster?.customerName, element.warehouseInfo?.plantName, this.filterForm.value.auditType,
              element.emptyAudit?.completedActionBin, element.emptyAudit?.attemptBinCnt,
              element.emptyAudit?.correctBin, element.emptyAudit?.discrepancyBin, element.emptyAudit?.discrepancyQty,
              element.emptyAudit?.binAccuracyPercentage, element.emptyAudit?.attemptBinPercentage, element.emptyAudit?.remarks
            ])
          });
      }

      var ws = XLSX.utils.aoa_to_sheet(filteredData);

      const wb = XLSX.utils.book_new();

      const todayDateTime = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
      this.progress = 0;
      this.pageIndex = 0;
      this.data = []
      this.showExcel = false
      this.showReason = false
      const filename = `Audit-report ${todayDateTime}.xlsx`;

      XLSX.utils.book_append_sheet(wb, ws, 'Audit-report');
      XLSX.writeFile(wb, filename);
    }
  }
}
