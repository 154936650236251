import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { Configuration } from '../constants/constants/config';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private api : ApiService,private API_URL: Configuration,private http: HttpClient
  ) { }

  getdefaultLogicDetails(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_DEFAULT_LOGIC_DETAILS}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  updateCalculation(form:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPDATE_CALCULATION_DETAILS}`;
    return this.api.post(url, form);
  }

  resetCalculationLogic(form:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.RESET_CALCULATION_LOGIC}`;
    return this.api.post(url, form);
  }

  addCalculation(form:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_CALCULATION_LOGIC}`;
    return this.api.post(url, form);
  }

  getReasonInfo(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_REASON_INFO}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  getCutoffDetails(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_CUTOFF_DETAILS}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }
  testCalculation(clientId:any){
    const url = `${environment.BASE_URL}gr/process-last?clientId=${clientId}`;
    return this.api.get(url);
  }
  getColumnNameList(){
    const url = `${environment.BASE_URL}calculation/getTblColumnNames`;
    return this.api.get(url);
  }
  getCustomerDetails(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}customer/getCustomerDetails?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }
  getwarehouseDetails(filter:{}, pageNumber:number, pageSize:number){
    const url = `${environment.BASE_URL}warehouseInfo/getwarehouseInfoDetails?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }
  addUpdateCustomer(data:any){
    const url = `${environment.BASE_URL}customer/addUpdateCustomerDetails`;
    return this.api.post(url, data);
  }
  addUpdateWarehouse(data:any){
    const url = `${environment.BASE_URL}warehouseInfo/addUpdatewarehouseInfoDetails`;
    return this.api.post(url, data);
  }
  addUpdateCutoff(data:any){
    const url = `${environment.BASE_URL}cutoff/addUpdateCutoffDetails`;
    return this.api.post(url, data);
  }
  deleteCutoffDetails(id:any){
    const url = `${environment.BASE_URL}cutoff/deleteCutoffDetails?id=${id}`;
    return this.api.delete(url);
  }
  testCalculationProcess(clientId:any,type:any){
    let url = ''
    if(type === 'GR'){
      url = `${environment.BASE_URL}gr/process-last?clientId=${clientId}`;
    }
    else{
      url = `${environment.BASE_URL}gi/process-last?clientId=${clientId}`;
    }
    return this.api.get(url);
  }
  deleteReasonsDetails(id:any){
    const url = `${environment.BASE_URL}reason/deleteReasonDetails?id=${id}`;
    return this.api.delete(url);
  }
  uploadReasonData(file: File,type:any,id:any,role:any): Observable<HttpEvent<any>>{
    var url :any
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({
      'VER': '1',
      'DEVICE-TYPE':'Web'
    });
    url = `${environment.BASE_URL}uploads/uploadReasonFileData?role=${role}&fileType=${type}&clientId=${id}`;
    
    const req = new HttpRequest('POST', url, formData, {
      headers,
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }
  updateReason(data:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPDATE_REASON}`;
    return this.http.post(url, data);
  }
}
