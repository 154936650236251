import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { AbsentismAttritionComponent } from '../component/absentism-attrition/absentism-attrition.component';
import { SafetyNearmissComponent } from '../component/safety-nearmiss/safety-nearmiss.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { SharedModule } from '';
import { AppMaterialModule } from 'src/app/_material/material/material.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';
import { SafetyIncidentRegisterComponent } from '../component/safety-incident-register/safety-incident-register.component';
import { CustomerComplaintComponent } from '../component/customer-complaint/customer-complaint.component';
import { AuditReportComponent } from '../component/audit-report/audit-report.component';
import { FireExtinguisherComponent } from '../component/fire-extinguisher/fire-extinguisher.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    AbsentismAttritionComponent,
    SafetyNearmissComponent,
    SafetyIncidentRegisterComponent,
    CustomerComplaintComponent,
    AuditReportComponent,
    FireExtinguisherComponent,
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,MultiSelectModule,
    FormsModule,DatePipe, SharedModule, AppMaterialModule, ReactiveFormsModule,OverlayPanelModule, DropdownModule
  ]
})
export class ReportsModule { }
