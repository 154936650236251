import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { FormatForExcelNearmissPipe } from 'src/app/_pipe/format-for-excel-nearmiss.pipe';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/shared.service';
import { MasterService } from '../../master.service';
import { Subject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { AddWarehouseComponent } from '../add-warehouse/add-warehouse.component';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent {

  minDate: any
  maxDate: any;
  theme = 'dark'
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  pageEvent: any;
  totalRecords!: number;
  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;
  loader: boolean = false;
  warehouseList: any[] = [];
  customerList: any[] = [];
  selectedWarehouse: any;
  selectedCustomer: any;
  userRole: any;
  warehouseId: any;
  userDetails: any;
  pmsData: any = [];
  SelectedStartDate: any;
  SelectedEndDate: any;
  isChecked: boolean = true;
  processedAbsentismList: any = [];
  confirmationDialogDisplay: boolean = false;
  progress: number = 0;
  showExcel = false;
  showReason = false;
  data: any[] = [];
  totalCalls: any;
  chunkSize: any;
  warehouseData: any[] = [];
  constructor(private fb: FormBuilder, public dialog: MatDialog, private auth: AuthService, private datePipe: DatePipe, private messageService: MessageService,
    private sharedService: SharedService, private formatNearmess: FormatForExcelNearmissPipe,
    private masterService: MasterService) {

  }

  ngOnInit(): void {
    const obj = this.setFilter();
    // this.getAbsentismDetail(obj, this.pageIndex, this.pageSize);
    const warehouselist = JSON.parse(localStorage.getItem('warehouseList') || '[]');

    const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
    this.customerList = customerList ? customerList : [];

    this.warehouseList = warehouselist ? warehouselist : [];
    this.selectedWarehouse = []
    if (this.userRole === 'ROLE_USER') {
      this.warehouseId = this.userDetails.userWarehouseMapping[0].warehouseId
      this.warehouseList.filter((el: any) => {
        if (el.id === this.warehouseId) {
          this.selectedWarehouse.push(el.plantName)
        }
      })
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }
    else {
      this.selectedWarehouse = []
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }
    // const obj = this.setFilter();
    this.getwarehouseDetails(obj, this.pageIndex, this.pageSize);
  }

  filterForm = this.fb.group({
    warehouse: new FormControl(),
    customer: new FormControl()
  });

  getwarehouseDetails(filter: any, pageIndex: any, pageSize: any) {

    this.masterService.getwarehouseDetails(filter, pageIndex, pageSize).subscribe((res: any) => {
      if (res != null) {
        this.loader = false;
        this.length = res?.responseObject.totalElements;
        this.warehouseData = res?.responseObject.content;
        this.chunkSize = 250;
        this.totalCalls = Math.ceil(this.totalRecords / this.chunkSize);
        this.totalRecords = this.length;

        console.log(this.warehouseData)
      }
    })
  }

  reset() {
    this.filterForm.reset();
    const obj = this.setFilter()
    this.getwarehouseDetails(obj, 0, this.pageSize)
    this.progress = 0
    this.showExcel = false
  }

  applyFilter() {
    const obj = this.setFilter();
    this.getwarehouseDetails(obj, this.pageIndex, this.pageSize)
  }

  setFilter() {
    const obj = [];
    if (this.filterForm.controls.warehouse.value && this.selectedWarehouse.length > 0) {
      obj.push({
        "key": "WAREHOUSE_IN",
        "value": this.selectedWarehouse.join(',')
      })
    }
    if (this.filterForm.controls.customer.value) {
      let customerValue = this.filterForm.controls.customer.value;
      obj.push({
        "key": "CUSTOMER_ID_IN",
        "value": customerValue.customerName
      })
    }

    return obj;
  }

  selectWarehouse(event: any) {
    this.selectedWarehouse = []
    if (event.value.length > 0) {
      event.value.forEach((element: any) => {
        this.selectedWarehouse.push(element.plantName);
      });
    }
  }

  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent, this.length, this.pageIndex, this.pageIndex)
    this.allChecked = false;
    const obj = this.setFilter()
    this.getwarehouseDetails(obj, this.pageIndex, this.pageSize);
  }

  addUpdateCustomer(data: any,type:any): void {
    console.log(data)
    let dialogRef = this.dialog.open(AddWarehouseComponent, {
      panelClass: 'custom-modalbox',
      data: {
        updateData: data ? data : null,
        type: type,
        id: data.id ? data.id : null
      }

    });
    dialogRef.afterClosed().subscribe((result) => {
      const obj = this.setFilter()
      this.getwarehouseDetails(obj, 0, this.pageSize)
    })
  }

}

