import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AbsentismAttritionComponent } from '../component/absentism-attrition/absentism-attrition.component';
import { SafetyNearmissComponent } from '../component/safety-nearmiss/safety-nearmiss.component';
import { FireExtinguisherComponent } from '../component/fire-extinguisher/fire-extinguisher.component';
import { SafetyIncidentRegisterComponent } from '../component/safety-incident-register/safety-incident-register.component';
import { CustomerComplainModalComponent } from 'src/app/setting/component/kpi/modals/customer-complain-modal/customer-complain-modal.component';
import { CustomerComplaintComponent } from '../component/customer-complaint/customer-complaint.component';
import { AuditReportComponent } from '../component/audit-report/audit-report.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
    data: { title: 'Dashboard' }
  },
  {
    path: 'absentism-attrition',
    component: AbsentismAttritionComponent,
    // canActivate: [AuthGuard],
    data: { title: 'Dashboard - Absentism Attrition' }
  },
  {
    path: 'safety-nearmiss',
    component: SafetyNearmissComponent,
    // canActivate: [AuthGuard],
    data: { title: 'Dashboard - Safety Nearmis' }
  },
  {
    path: 'safety-incident-register',
    component: SafetyIncidentRegisterComponent,
    // canActivate: [AuthGuard],
    data: { title: 'Dashboard - Safety Incident register' }
  },
  {
    path: 'fire-extinguisher',
    component: FireExtinguisherComponent,
    // canActivate: [AuthGuard],
    data: { title: 'Dashboard - Fire Extinguisher' }
  },
  {
    path: 'customer-complaint',
    component: CustomerComplaintComponent,
    // canActivate: [AuthGuard],
    data: { title: 'Dashboard - Customer Complaint' }
  },
  {
    path: 'audit-report',
    component: AuditReportComponent,
    // canActivate: [AuthGuard],
    data: { title: 'Dashboard - Audit Report' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
