import { DatePipe } from '@angular/common';
import { Component, NgModule, SecurityContext } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
import * as XLSX from 'xlsx';
import { FormatNearmessPipe } from 'src/app/_pipe/format-nearmess.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormatForExcelNearmissPipe } from 'src/app/_pipe/format-for-excel-nearmiss.pipe';
interface Header {
  main?: string;
  sub?: string[];
}

@Component({
  selector: 'app-safety-nearmiss',
  templateUrl: './safety-nearmiss.component.html',
  styleUrls: ['./safety-nearmiss.component.css']
})

export class SafetyNearmissComponent {
  minDate: any
  maxDate: any;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  pageEvent: any;
  totalRecords!: number;

  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;

  warehouseList: any[] = [];
  customerList: any[] = [];
  selectedWarehouse: any;
  selectedCustomer: any;
  userRole: any;
  warehouseId: any;
  userDetails: any;
  loader: boolean = false;
  safetyData: any = [];
  progress: number = 0;
  showExcel = false;
  showReason = false;
  data: any[] = [];
  totalCalls: any;
  chunkSize: any;
  confirmationDialogDisplay: boolean = false;
  SelectedStartDate: any;
  SelectedEndDate: any;

  theme = 'dark'
  constructor(private fb: FormBuilder, public dialog: MatDialog, private auth: AuthService, private datePipe: DatePipe, private messageService: MessageService,
    private reportService: ReportsService, private formatNearmess: FormatForExcelNearmissPipe, private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit(): void {
    const warehouselist = JSON.parse(localStorage.getItem('warehouseList') || '[]');
    this.warehouseList = warehouselist ? warehouselist : [];
    this.selectedWarehouse = [];

    const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
    this.customerList = customerList ? customerList : [];

    const obj = this.setFilter();
    this.getSafetyNearmiss(obj, this.pageIndex, this.pageSize);

    if (this.userRole === 'ROLE_USER') {
      this.warehouseId = this.userDetails.userWarehouseMapping[0].warehouseId
      this.warehouseList.filter((el: any) => {
        if (el.id === this.warehouseId) {
          this.selectedWarehouse.push(el.plantName)
        }
      })
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }
    else {
      this.selectedWarehouse = []
      this.filterForm.controls.warehouse.setValue(this.selectedWarehouse)
    }
  }

  filterForm = this.fb.group({
    rangeDates: new FormControl(''),
    warehouse: new FormControl(),
    customer: new FormControl()
  })

  getSafetyNearmiss(filter: any, pageIndex: any, pageSize: any) {
    this.reportService.getAllSafetyAndNearmiss(filter, pageIndex, pageSize).subscribe((res: any) => {
      if (res != null) {
        this.loader = false;
        this.length = res?.responseObject.totalElements
        this.safetyData = res?.responseObject.content;
        this.chunkSize = 250;
        this.totalCalls = Math.ceil(this.totalRecords / this.chunkSize);

        this.totalRecords = this.length;
      }
    })
  }
  showConfirmationDialog() {
    this.confirmationDialogDisplay = true;
  }


  isHeaderObject(header: string | Header): header is Header {
    return (header as Header).main !== undefined;
  }

  reset() {
    this.filterForm.reset();
    const obj = this.setFilter();
    this.getSafetyNearmiss(obj, 0, this.pageSize);
    this.progress = 0
    this.showExcel = false
  }

  selectRange(event: any) {
    if (this.filterForm.value.rangeDates![0]) {
      this.minDate = this.filterForm.value.rangeDates![0]
      var futureMonth = moment(this.minDate).add(1, 'M').add(15, 'days');
      var futureMonthEnd = moment(futureMonth, 'DD-MM-YYYY').format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ (India Standard Time)');
      this.maxDate = new Date(futureMonthEnd)
    }
  }

  selectWarehouse(event: any) {
    this.selectedWarehouse = []
    if (event.value.length > 0) {
      event.value.forEach((element: any) => {
        this.selectedWarehouse.push(element.id)
      });
    }
  }

  setFilter() {
    const obj = [];
    if (this.filterForm.controls.warehouse.value && this.selectedWarehouse.length > 0) {
      obj.push({
        "key": "WAREHOUSE_ID_IN",
        "value": this.selectedWarehouse.join(',')
      })
    }
    if (this.filterForm.controls.customer.value) {
      let customerValue = this.filterForm.controls.customer.value;
      obj.push({
        "key": "CUSTOMER_ID_IN",
        "value": customerValue.id
      })
    }
    if (this.filterForm.controls.rangeDates.value) {
      if (this.filterForm.controls.rangeDates.value[0]) {
        this.SelectedStartDate = moment(this.filterForm.controls.rangeDates.value[0]).format('DD-MM-yyyy ZZ')
        obj.push({
          "key": "FROM_DATE",
          dateValue: this.SelectedStartDate ? this.SelectedStartDate : ''
        })
      }
      if (this.filterForm.controls.rangeDates.value[1]) {
        this.SelectedEndDate = moment(this.filterForm.controls.rangeDates.value[1]).format('DD-MM-yyyy ZZ')
        obj.push({
          "key": "TO_DATE",
          dateValue: this.SelectedEndDate ? this.SelectedEndDate : ''
        })
      }
    }
    return obj;
  }

  applyFilter() {
    const obj = this.setFilter();
    this.getSafetyNearmiss(obj, this.pageIndex, this.pageSize);
  }

  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent, this.length, this.pageIndex, this.pageIndex)
    this.allChecked = false;
    const obj = this.setFilter();
    this.getSafetyNearmiss(obj, this.pageIndex, this.pageSize);
  }

  updateData(absentismData: any) {
    console.log(absentismData)
  }

  fetchDataAndCreateExcel(type: any) {
    if (type === 'excel') {
      this.showExcel = true
      // this.showReason =false
    }
    else {
      this.showExcel = false
      // this.showReason =true
    }
    if (this.length > 50000) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Record count has surpassed the 50,000 limit. Please refine your search criteria.',
      });
      return
    }
    this.fetchDataInPages(type);
  }

  fetchDataInPages(type: any) {
    const obj = this.setFilter()
    if (this.pageIndex < this.totalCalls) {
      this.progress = this.progress === 0 ? 1 : this.progress
      this.reportService.getAllSafetyAndNearmiss(obj, this.pageIndex, this.chunkSize).subscribe((res: any) => {
        if (res?.responseObject?.content?.length > 0) {
          this.data = this.data.concat(res?.responseObject.content);
          this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
          this.pageIndex++;

          if (this.pageIndex < this.totalCalls) {
            this.fetchDataInPages(type);
          } else {
            this.createExcel(type);
          }
        } else {
          this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'No data found',
          });
          return
        }
      });
    }
  }

  createExcel(type: any) {
    if (this.data.length > 0 && type === 'excel') {

      const filteredData = [
        ['S.No', 'Date', 'Customer_Name', 'Warehouse Name', 'Safety committee meeting(1/0)', 'Employee Participated in Safety meeting',
          'Safety committee meeting Hours', 'HSE Training(1/0)', 'Employee Participated in HSE meeting', 'HSE Traning meeting Hours',
         'Total Unsafe Condition', 'Unsafe condition', 'Total Unsafe Act', 'Unsafe Act','Total Near-miss', 'Nearmess'
        ]
      ];
      const filteredDataSource = this.data;

      filteredDataSource.forEach((element: any, index: number) => {
        const rowData = [
          index + 1,
          element.recordDate, element.customerMaster?.customerName, element.warehouseInfo?.plantName, element.safetyCommitteeMeeting, element.employeeParticipatedInSafetyMeeting,
          element?.safetyCommitteeMeetingHours, element?.hseTraining, element?.employeeParticipatedInHseMeeting, element?.hseTrainingMeetingHours,
          element?.unsafeCondition,
          element?.unsafeConditionList ? this.formatNearmess.transform(element.unsafeConditionList).toString() : '',
          element?.unsafeAct,
          element?.unsafeActList ? this.formatNearmess.transform(element.unsafeActList).toString() : '',
          element?.nearmiss,
          element?.nearmissList ? this.formatNearmess.transform(element.nearmissList).toString() : '',

        ]

        filteredData.push(rowData);
      });
      var ws = XLSX.utils.aoa_to_sheet(filteredData);

      const wb = XLSX.utils.book_new();
      // Generate today's date in the format DD-MM-YYYY
      const todayDateTime = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
      this.progress = 0;
      this.pageIndex = 0;
      this.data = []
      this.showExcel = false
      this.showReason = false
      const filename = `Safety-nearmiss ${todayDateTime}.xlsx`;

      XLSX.utils.book_append_sheet(wb, ws, 'Safety-nearmiss');
      XLSX.writeFile(wb, filename);
    }
  }
}
