import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SharedService {
    loaderState = new Subject();
    loaderState$ = this.loaderState.asObservable();
    
    loaderState1 = new Subject();
    loaderState1$ = this.loaderState1.asObservable();

    constructor() { }

    /**
     * To update loader across the app
     * @param state Show/Hide
     */
    updateLoader(state:any) {
        this.loaderState.next(state);
    }
    updateLoader1(state: any) {
        this.loaderState1.next(state);
    }

    // accept only numbers and float only 
    isNumberKey(evt:any) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        var value = evt.target.value;
        var dotcontains = value.indexOf(".") != -1;
        if (dotcontains)
            if (charCode == 46) return false;
        if (charCode == 46) return true;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }
}
