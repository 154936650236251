<div class="">
  <div class="content">
    <div class="order-details">
      <div class=" p-0">
        <div class="page-header">
          <div class="page-title" style="margin-left: -10px;">
            <h4>Add PMS KPI</h4>
          </div>
          <div class="close">
            <a mat-dialog-close="" (click)="closeDialog()">
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="#333" stroke-width="2" fill="none"
                stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </a>
          </div>
        </div>

        <!-- /product list -->
        <div class="card border-none">
          <div class="card-body p-0">
            <form [formGroup]="pmsForm" (ngSubmit)="onSubmit(pmsForm)">

              <div class="row">
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group required">
                    <label>Warehouse</label>
                    <mat-form-field class="custom-select-ui status-field">
                      <mat-select style="padding:3px 0" placeholder="Warehouse Name" formControlName="warehouseName"
                        (selectionChange)="selectWarehouse($event)">
                        <mat-option *ngFor="let item of warehouseList" [value]="item.plantName">{{item.plantName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group required">
                    <label>Customer</label>
                    <input class="form-control" [ngModel]="customerName" type="text" formControlName="customerName" />
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>PMS Date <span class="manitory">*</span></label>
                    <p-calendar  formControlName="pmsDate" />
                    
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Remark Not Updation <span class="manitory">*</span></label>
                    <input class="form-control" type="text" formControlName="remarkNotUpdation">
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Cycle Count <span class="manitory">*</span></label>
                    <input class="form-control" type="text" formControlName="pmsCycleCount">
                    <!-- <div *ngIf="submitted && form['firstName'].errors || form['firstName'].touched" class="invalid-feedback">
                                  <div *ngIf="form['firstName'].errors?.['required']">First name is required</div>
                              </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Physical Stock Qty <span class="manitory">*</span></label>
                    <input class="form-control" type="text" formControlName="pmsPhysicalStockQty">
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Actual Stock Qty <span class="manitory">*</span></label>
                    <input class="form-control" type="text" formControlName="pmsActualStockQty">
                    <!-- <div *ngIf="submitted && form['firstName'].errors || form['firstName'].touched" class="invalid-feedback">
                                  <div *ngIf="form['firstName'].errors?.['required']">First name is required</div>
                              </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Discrepancy Remark <span class="manitory">*</span></label>
                    <input class="form-control" type="text" formControlName="pmsDiscremencyRemark">
                  </div>
                </div>
              </div>

              <div class="row mt-2 mb-3">
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2" >Submit</button>
                  <a href="javascript:void(0);" class="btn btn-cancel" (click)="closeDialog()">Cancel</a>
                </div>
              </div>


            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>