import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'formatNearmess'
})
export class FormatNearmessPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any): SafeHtml {
    if (!value || !Array.isArray(value)) {
      return '';
    }
    
    const formattedHtml = value.map((item, index) => {
      const isLast = index === value.length - 1;
      return `
      <div>
        ${item.dropdownMaster.value}: <strong>${item.userValue}</strong>
      </div>
      ${isLast ? '' : '<hr />'}
      `;
    }).join('');

    return this.sanitizer.bypassSecurityTrustHtml(formattedHtml);
  }
}
