import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Configuration } from 'src/app/constants/constants/config';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  BASE_DOMAIN = environment.BASE_DOMAIN
  ACCESS_TOKEN = 'access_token';
  USER_DETAILS = 'user_details'; 

  constructor(private api : ApiService,private API_URL: Configuration,private http: HttpClient,private _route:Router
    ,private cookieService: CookieService
    ) { }


    /**
   * @author Anup Kumar
   * @description sets user authentication token
   * @param {string} accessToken authentication token
   */
  setAuth(accessToken:any) {
    // console.log("token",accessToken)
    this.cookieService.set(this.ACCESS_TOKEN, accessToken, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  } 
  userDetailsSet(userDetails:any) {
    // console.log("userDetails",userDetails)
    this.cookieService.set(this.USER_DETAILS, JSON.stringify(userDetails), 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  } 

  removeAuthToken() {
    // Remove token from storage
    this.cookieService.delete(this.ACCESS_TOKEN);
  }

  /**
   * @author Anup Kumar
   * @description returns user authentication token
   * @return {string} null if token not present
   */
  getAuthToken() {
    if (this.cookieService.get(this.ACCESS_TOKEN) && this.cookieService.get(this.ACCESS_TOKEN) !== '') {
      return this.cookieService.get(this.ACCESS_TOKEN);
    } else {
      return null;
    }
  }

  getUserDetails() {
    if (this.cookieService.get(this.USER_DETAILS) && this.cookieService.get(this.USER_DETAILS) !== '') {
      return this.cookieService.get(this.USER_DETAILS);
    } else {
      return null;
    }
  }
  signUp(user:any) {
    return this.api.post(environment.LOGIN_DOMAIN+`${this.API_URL.CONFIG_URL.SIGNUP}`, user);
  }
  getUser(getUser:any) {
    // console.log("user",getUser)
    return this.api.post(environment.LOGIN_DOMAIN+`${this.API_URL.CONFIG_URL.USER_LOGIN}`, getUser);
  }


  getWarehouseId(id:any){
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.GET_MULTI_WAREHOUSE_BY_ID}${id}`;
    return this.api.post(url , id);
  }
  getMultiClientById(ids:any){
    // console.log("__________",id)
    let clientList = '?clientList='
    const urlids = '' 
    if(ids){
      const urlids = clientList + ids
    }
    console.log(urlids)
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.GET_MULTI_CLIENTS_BY_ID}${urlids}`;
    console.log(url)

    return this.api.post(url,urlids);
  }

  getOrderTracking(orderNumber:string){
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.GET_TRACKING_STATUS_BY_ID}${orderNumber}`;
    return this.api.post(url,orderNumber);
  }

  trackOrder(orderNumber:string){
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.GET_TRACKING_ORDER}${orderNumber}`;
    return this.api.get(url);
  }

  
  getreturReason(){
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.GET_RETURN_REASON}`;
    return this.api.get(url);
  }

  AllmergeLabels(orderNumbers:string){
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.MERGE_LABELS}`;
    return this.api.post(url,orderNumbers);
  }

  pendingLabelsPrint(orderNumbers:string){
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.PENDING_MERGE_LABELS}${orderNumbers}`;
    return this.api.post(url,orderNumbers);
  }

  bulkOrderUpload( file: File) {
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.BULK_ORDER_UPLOAD}`;
   
      const formData: FormData = new FormData();
  
      formData.append('file', file);
      return this.api.post(url, formData);

  }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({
      'VER': '1',
      'DEVICE-TYPE':'Web'
    });
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const req = new HttpRequest('POST', `https://dev.goalfa.in/utils-service/uploads/uploadOrders?Id=1`, formData, {
      headers,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }


  createReturn(payload:{}){
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.CREATE_ORDER_RETURN}`;
    return this.api.post(url,payload);
  }
 
  getOrderSummary(){
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.ORDER_SUMMERY}`;
    return this.api.get(url);
  }

  getOrderList(filter:{},pageNumber:number,pageSize:number) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_ORDER_UPDATE_LIST}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url,filter);
  }
  getCalList(filter:{},pageNumber:number,pageSize:number) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_CAL_DETAILS}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url,filter);
  }
  getGRNList(filter:{},pageNumber:number,pageSize:number) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_GRN_DETAILS}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url,filter);
  }
  getOrderLineList(filter:{},pageNumber:number,pageSize:number) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_ORDERLINE_DETAILS}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url,filter);
  }
  handleDate(dateString:any){
    var dateParts = dateString.split("-");
    var year = parseInt(dateParts[2], 10);
    var month = parseInt(dateParts[1], 10) - 1; // Month is zero-based
    var day = parseInt(dateParts[0], 10);

    // Create a Date object with the given date
    var originalDate = new Date(year, month, day);

    originalDate.setHours(originalDate.getHours() + 5);
    originalDate.setMinutes(originalDate.getMinutes() + 30);
    return originalDate
  }
  updateCalendar(data:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.CALENDAR}/updateCalendarDetails`;
    return this.api.post(url,data);
  }
  updateOrderLine(data:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ORDERS}/updateOrderLine`;
    return this.api.post(url,data);
  }
  updateOrderData(data:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ORDERS}/updateOrderData`;
    return this.api.post(url,data);
  }
  updateGrn(data:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GRN}/updateGrnData`;
    return this.api.post(url,data);
  }

  uploadFile(file: File): Observable<HttpEvent<any>> {
    var url :any
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({
      'VER': '1',
      'DEVICE-TYPE':'Web'
    });
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPLOAD}/uploadFile`;
    const req = new HttpRequest('POST', url, formData, {
      headers,
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  uploadFe(data:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.fe}/addFeDetails`;
    return this.api.post(url,data);
  }

  uploadOrder(file: File,type:any,id:any,role:any): Observable<HttpEvent<any>> {
    var url :any
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({
      'VER': '1',
      'DEVICE-TYPE':'Web'
    });
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    let sendId = id ? `?Id=${id}` : ''
    let sendrole = role ? (id ? `&role=${role}` : `?role=${role}`) : ''
    if(type === 'ORDER'){
      url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPLOAD}/uploadOrderData${sendId}${sendrole}`;
    }
    else if(type === 'GRN'){
      url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPLOAD}/uploadGrnData${sendId}${sendrole}`;
    }
    else if(type === 'Reason_of_Order'){
      url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPLOAD}/uploadOrderReasons${sendId}${sendrole}`;
    }
    else if(type === 'Reason_of_GRN'){
      url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPLOAD}/uploadGrnReasons${sendId}${sendrole}`;
    }
    else if(type === 'Order_Line'){
      url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPLOAD}/uploadOrderLineData${sendId}${sendrole}`;
    }

    const req = new HttpRequest('POST', url, formData, {
      headers,
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  uploadForOtherClient(file: File,fileType:any, warehouseId:any, role:any,  clientId:any): Observable<HttpEvent<any>>  {
    let url = ''
    if(fileType === 'GIKPI' || fileType === 'GI90'){
      url = `${environment.BASE_URL}uploads/uploadGiFileData?warehouseId=${warehouseId}&role=${role}&fileType=${fileType}&clientId=${clientId}`;
    }
    else if(fileType === 'PMSIN'){
      url = `${environment.BASE_URL}uploads/uploadPmsInboundFileData?warehouseId=${warehouseId}&role=${role}&fileType=${fileType}&clientId=${clientId}`;
    }
    else if(fileType === 'PMSOUT'){
      url = `${environment.BASE_URL}uploads/uploadPmsOutboundFileData?warehouseId=${warehouseId}&role=${role}&fileType=${fileType}&clientId=${clientId}`;
    }
    else if(fileType === 'PICKFAIL'){
      url = `${environment.BASE_URL}uploads/uploadPickFailFileData?warehouseId=${warehouseId}&role=${role}&fileType=${fileType}&clientId=${clientId}`;
    }
    else{
      url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPLOAD}/upload?warehouseId=${warehouseId}&role=${role}&fileType=${fileType}&clientId=${clientId}`;
    }

    const formData: FormData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({
      'VER': '1',
      'DEVICE-TYPE': 'Web'
    });

    const req = new HttpRequest('POST', url, formData, {
      headers,
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  getFeAuditFileList(filter:{},pageNumber:number,pageSize:number) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.fe}/getFeFileDataByFilter?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url,filter);
  }
  getFeDataByFile(filter:{},pageNumber:number,pageSize:number) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.fe}/getFeDataByFilter?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url,filter);
  }
  getFieldMappingDetails(fileType:any, clientId:any){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_FIELD_MAPPING_DETAILS}?fileType=${fileType}&clientId=${clientId}`;
    return this.api.get(url);
  }
  addFileFieldMappingDetails(data:any): Observable<any> {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_FILE_FIELD_MAPPING_DETAILS}`;
    return this.http.post(url, data);
  }
  updateFileFieldMappingDetails(data:any): Observable<any> {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_FILE_FIELD_MAPPING_DETAILS}`;
    return this.http.post(url, data);
  }
  getGrDetails(filter:{},pageNumber:number,pageSize:number){
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_GR_DETAIL}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url,filter);
  }
  getMappingDetails(fileType:any, clientId:any,type:any){
    let url = ''
    if(type === 'gr'){
      url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_MAPPING_DETAILS}?fileType=${fileType}&clientId=${clientId}`;
    }
    else{
      url = `${environment.BASE_URL}gi/getMappingDetails?fileType=${fileType}&clientId=${clientId}`;
    }
    return this.api.get(url);
  }
  getGiDetails(filter:{},pageNumber:number,pageSize:number){
    const url = `${environment.BASE_URL}gi/getGiDetails?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url,filter);
  }
}
