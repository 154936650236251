<div class="page-wrapper" >
    <div class="content">
        <div class="order-details">
            <div class="content p-0">
                <div class="page-header">
                    <div class="page-title d-flex justify-content-between w-100">
                        <h4>Fire Extinguisher</h4>
                        
                    </div>
                </div>


                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top position-relative">
                           
                        </div>
                        
                       
                        
                    </div>
                </div>
                <div class="pagination search-set justify-content-end flex-end w-100">
                    <!-- <mat-paginator class="demo-paginator" (page)="pageNavigations($event)" [length]="length" [pageSize]="pageSize"
                            [pageSizeOptions]="[5, 10, 25, 50, 100]"
                            aria-label="Select page">
                </mat-paginator> -->

                </div>
            </div>
        </div>
    </div>
</div>
