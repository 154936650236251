import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { HeaderComponent } from './component/header/header.component';
import { CustomDatePipe } from '../_pipe/datepipe.pipe';
import { AppMaterialModule } from '../_material/material/material.module';
import { CustomDateRangeComponent } from './component/custom-date-range/custom-date-range.component';
import { dateRangeComponent } from './component/date-range/date-range.component';
import { searchDropDownFilter } from '../_pipe/search-filter.pipe';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmationDialogComponent } from './component/confirmation-dialog/confirmation-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { ChangePasswordModalComponent } from './component/changePassword-modal/changePassword-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormatNearmessPipe } from '../_pipe/format-nearmess.pipe';
import { FormatForExcelNearmissPipe } from '../_pipe/format-for-excel-nearmiss.pipe';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';


@NgModule({
  declarations: [
    CustomDatePipe,
    CustomDateRangeComponent,
    dateRangeComponent,
    searchDropDownFilter,
    ConfirmationDialogComponent,
    ChangePasswordModalComponent,
    FormatNearmessPipe, 
    FormatForExcelNearmissPipe
  ],
  exports: [
    CustomDatePipe,
    CustomDateRangeComponent,
    dateRangeComponent,
    searchDropDownFilter,
    MultiSelectModule,
    CalendarModule,
    ConfirmationDialogComponent,
    DialogModule,
    ChangePasswordModalComponent,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    ToastModule,
    NgSelectModule,
    FormatNearmessPipe,
    FormatForExcelNearmissPipe,
    ConfirmDialogModule
  ],
  imports: [CommonModule, AppMaterialModule, MultiSelectModule, DialogModule,FormsModule,ReactiveFormsModule,ProgressBarModule,ToastModule,NgSelectModule, ConfirmDialogModule],

  providers:[FormatNearmessPipe, FormatForExcelNearmissPipe, ConfirmationService]
})

// @NgModule({
//   declarations: [
//     HeaderComponent,
//     SidebarComponent
//   ],
//   imports: [
//     CommonModule
//   ],
//   exports:[
//     HeaderComponent,
//     SidebarComponent
//   ]
// })
export class SharedModule {}
