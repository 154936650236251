import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PmsInboundComponent } from './components/pms-inbound/pms-inbound.component';
import { PmsOutboundComponent } from './components/pms-outbound/pms-outbound.component';
import { PmsKpiTableComponent } from './components/pms-kpi-table/pms-kpi-table.component';
import { PickFailKpiComponent } from './components/pick-fail-kpi/pick-fail-kpi.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
    data: { title: 'Dashboard - Order' }
  },
  {
    path: 'pms-inbound',
    component: PmsInboundComponent,
    data: { title: 'Dashboard - PMS-Inbound' }
  },
  {
    path: 'pms-outbound',
    component: PmsOutboundComponent,
    data: { title: 'Dashboard - PMS-Outbound' }
  },
  {
    path: 'pms-kpi',
    component: PmsKpiTableComponent,
    data: { title: 'Dashboard - PMS-KPI' }
  },
  {
    path: 'pick-fail-kpi',
    component: PickFailKpiComponent,
    data: { title: 'Dashboard - Pick-fail-KPI' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UpdateRoutingModule { }
