<div class="sidebar" id="sidebar " [ngClass]="[theme === 'dark'? 'dark' : '']">
    <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
            <ul>
                <li class="submenu-open" *ngIf="roleService.isActiveModule('Utilities')">
                    <h6 class="submenu-hdr">Utilities</h6>
                    <ul>
                        <li *ngIf="customerName !== 'havells' && userRole === 'ROLE_SUPERUSER'" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="order/header-mapping"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltip="Header Mapping" matTooltipPosition="right" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1" xmlns="http://www.w3.org/2000/svg"><path d="M20,7H12.309a.5.5,0,0,1-.447-.276L10.276,3.553A1,1,0,0,0,9.382,3H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V8A1,1,0,0,0,20,7Zm-5,8H13v2a1,1,0,0,1-2,0V15H9a1,1,0,0,1,0-2h2V11a1,1,0,0,1,2,0v2h2a1,1,0,0,1,0,2Z"/></svg>
                            <span>Header Mapping</span></a>
                        </li>
                        <li *ngIf="roleService.isActiveSubmodule('Upload')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="order/import-order"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltip="Upload" matTooltipPosition="right" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                            <span>Upload</span></a>
                        </li>
                        <li *ngIf="roleService.isActiveSubmodule('Calendar')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="calender"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Calender" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                            <span>Calender</span></a></li>
                        
                        <li class="menu" *ngIf="roleService.isActiveSubmodule('KPI')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="setting/kpi"  id="mobile_btn" (click)="toggleNavMobile()">
                            <!-- <svg matTooltipPosition="right" matTooltip="KPI" fill="currentColor" width="22" height="22" viewBox="0 0 32 32" data-name="Layer 1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><rect height="1" width="12" x="10" y="29"></rect><rect height="1" width="12" x="10" y="2"></rect><rect height="1" width="9" x="13" y="20"></rect><rect height="1" width="2" x="10" y="20"></rect><rect height="1" width="9" x="13" y="23"></rect><rect height="1" width="2" x="10" y="23"></rect><rect height="1" transform="translate(9.5 41.5) rotate(-90)" width="20" x="15.5" y="15.5"></rect><path d="M22,2V3h2a1,1,0,0,1,1,1V6h1V4a2,2,0,0,0-2-2Z"></path><rect height="1" transform="translate(-9.5 22.5) rotate(-90)" width="20" x="-3.5" y="15.5"></rect><path d="M10,2V3H8A1,1,0,0,0,7,4V6H6V4A2,2,0,0,1,8,2Z"></path><path d="M22,30V29h2a1,1,0,0,0,1-1V26h1v2a2,2,0,0,1-2,2Z"></path><path d="M10,30V29H8a1,1,0,0,1-1-1V26H6v2a2,2,0,0,0,2,2Z"></path><path d="M20.67,15.2a5,5,0,0,0-9.34,0,4.46,4.46,0,0,0-.27,1.09,7.42,7.42,0,0,0,.94.64,3.8,3.8,0,0,1,.17-1.07,4,4,0,0,1,7.66,0A3.8,3.8,0,0,1,20,16.93a7.42,7.42,0,0,0,.94-.64A4.46,4.46,0,0,0,20.67,15.2Z"></path><path d="M16,8a2,2,0,1,0,2,2A2,2,0,0,0,16,8Zm0,3a1,1,0,1,1,1-1A1,1,0,0,1,16,11Z"></path><path d="M20.67,15.2a5,5,0,0,0-9.34,0,4.46,4.46,0,0,0-.27,1.09,7.42,7.42,0,0,0,.94.64,8,8,0,0,0,8,0,7.42,7.42,0,0,0,.94-.64A4.46,4.46,0,0,0,20.67,15.2ZM16,17a7,7,0,0,1-3.83-1.14,4,4,0,0,1,7.66,0A7,7,0,0,1,16,17Z"></path><path d="M20.67,15.2a6,6,0,0,1-.84.66,4,4,0,0,0-7.66,0,6,6,0,0,1-.84-.66,5,5,0,0,1,9.34,0Z"></path></g></svg> -->
                            <svg matTooltipPosition="right" matTooltip="KPI" fill="#000000" width="15" height="15" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                <g id="SVGRepo_iconCarrier">
                                <path d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z"/>
                                </g>
                            </svg>
                            <span>KPI</span></a>
                        </li>
                    </ul>
                </li>
                <li class="submenu-open" *ngIf="roleService.isActiveModule('Update')">
                    <h6 class="submenu-hdr">Update</h6>
                    <ul>
                        <li *ngIf="roleService.isActiveSubmodule('Order Update')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/order"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Order Report" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                            <span>Order Update</span></a></li>
                        <li class="menu" *ngIf="roleService.isActiveSubmodule('GRN Update')"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/grn-report"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="GRN Report"  width="15" height="15" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                    <g id="SVGRepo_iconCarrier">
                                    <path d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z"/>
                                    </g>
                            </svg>
                                <span>GRN Update </span></a></li>
                            <li *ngIf="roleService.isActiveSubmodule('Order Line')"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/order-line"  id="mobile_btn" (click)="toggleNavMobile()">
                                <svg matTooltipPosition="right" matTooltip="Order Line" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                <span>Order Line </span></a></li>
                                <ng-container *ngIf="customerName !== 'havells' && userRole === 'ROLE_SUPERUSER'">
                                    <li class="menu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="update/pms-inbound"  id="mobile_btn" (click)="toggleNavMobile()">
                                        <svg matTooltipPosition="right" matTooltip="PMS Inbound" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#313131" class="bi bi-telephone-inbound" stroke="#313131"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path> </g></svg>
                                        <span>PMS Inbound </span></a></li>
                                    <li class="menu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="update/pms-outbound"  id="mobile_btn" (click)="toggleNavMobile()">
                                        <svg matTooltipPosition="right" matTooltip="PMS Outbound" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#313131" class="bi bi-telephone-outbound" stroke="#313131"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"></path> </g></svg>
                                        <span>PMS Outbound</span></a></li>
                                    <li class="menu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="update/pms-kpi"  id="mobile_btn" (click)="toggleNavMobile()">
                                        <svg matTooltipPosition="right" matTooltip="PMS KPI TABLE" fill="#313131" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg" stroke="#313131"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <defs> <style> .cls-1 { fill: none; } </style> </defs> <path d="M7.6667,27.5027,2,22.4484l1.3311-1.4927,5.6411,5.0316,7.6906-7.4449a1.9282,1.9282,0,0,1,2.6736-.0084L22.96,21.9983l5.5791-5.9735L30,17.3905l-5.5812,5.9758a1.996,1.996,0,0,1-2.8379.08l-3.5765-3.4191-7.666,7.4206A1.9629,1.9629,0,0,1,7.6667,27.5027Z"></path> <polygon points="30 11 26 11 28 8 30 11"></polygon> <path d="M22,4H18V6h4V8H19v2h3v2H18v2h4a2.0027,2.0027,0,0,0,2-2V6A2.0023,2.0023,0,0,0,22,4Z"></path> <path d="M16,14H10V10a2.002,2.002,0,0,1,2-2h2V6H10V4h4a2.0023,2.0023,0,0,1,2,2V8a2.0023,2.0023,0,0,1-2,2H12v2h4Z"></path> <polygon points="6 12 6 4 4 4 4 5 2 5 2 7 4 7 4 12 2 12 2 14 8 14 8 12 6 12"></polygon> <rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" class="cls-1" width="32" height="32"></rect> </g></svg>
                                        <span>PMS KPI TABLE</span></a></li>
                                    <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="update/gi-kpi"  id="mobile_btn" (click)="toggleNavMobile()">
                                        <svg matTooltipPosition="right" matTooltip="Order Line" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                        <span>GI KPI </span></a></li>
                                    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="update/gi90"  id="mobile_btn" (click)="toggleNavMobile()">
                                        <svg matTooltipPosition="right" matTooltip="Order Line" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                        <span>GI 90 </span></a></li> -->
                                    <li class="menu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="update/pick-fail-kpi"  id="mobile_btn" (click)="toggleNavMobile()">
                                        <svg matTooltipPosition="right" matTooltip="Pick Fail KPI" fill="#313131" viewBox="0 -8 528 528" xmlns="http://www.w3.org/2000/svg" stroke="#313131"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><title>fail</title><path d="M264 456Q210 456 164 429 118 402 91 356 64 310 64 256 64 202 91 156 118 110 164 83 210 56 264 56 318 56 364 83 410 110 437 156 464 202 464 256 464 310 437 356 410 402 364 429 318 456 264 456ZM264 288L328 352 360 320 296 256 360 192 328 160 264 224 200 160 168 192 232 256 168 320 200 352 264 288Z"></path></g></svg>
                                        <span>Pick Fail KPI </span></a></li>
                                </ng-container>
                                
                            <!-- <li class="menu" *ngIf="roleService.isActiveSubmodule('FE Audit')"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/fe-audit"  id="mobile_btn" (click)="toggleNavMobile()">
                                <svg matTooltipPosition="right" matTooltip="FE Audit" width="20" height="20" viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><defs><style>.cls-1{fill:none;stroke:#020202;stroke-miterlimit:10;stroke-width:1.82px;}</style></defs><path class="cls-1" d="M10.14,5.64h0a4.55,4.55,0,0,1,4.55,4.55V22a0,0,0,0,1,0,0H5.59a0,0,0,0,1,0,0V10.18A4.55,4.55,0,0,1,10.14,5.64Z"></path><rect class="cls-1" x="5.59" y="12.91" width="9.09" height="4.55"></rect><rect class="cls-1" x="8.32" y="2" width="3.64" height="3.64"></rect><polygon class="cls-1" points="21.95 5.64 16.5 4.73 16.5 2.91 21.95 2 21.95 5.64"></polygon><line class="cls-1" x1="11.95" y1="3.82" x2="16.5" y2="3.82"></line><polyline class="cls-1" points="8.32 3.82 3.8 3.82 1.04 7.46"></polyline></g></svg>
                                <span>FE Audit</span></a></li> -->
                    </ul>
                </li>
                <li class="submenu-open" *ngIf="roleService.isActiveModule('Reports')">
                    <h6 class="submenu-hdr">Reports</h6>
                    <ul>
                        <li class="menu" *ngIf="roleService.isActiveSubmodule('Absentism & Attrition')" routerLinkActive="active"><a routerLink="reports/absentism-attrition"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Absentism & Attrition" viewBox="0 0 24 24" width="22" height="22" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                            <!-- <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg> -->
                            <span>Absentism & Attrition</span></a>
                        </li>
                        <li class="menu" *ngIf="roleService.isActiveSubmodule('Safety & Nearmiss')" routerLinkActive="active"><a routerLink="reports/safety-nearmiss"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Safety & Nearmiss" width="22" height="22" viewBox="0 0 1024 1024" fill="#000000" stroke="currentColor" stroke-width="2" class="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M908.008473 139.713707L529.299213 3.059374a51.005609 51.005609 0 0 0-17.397182-3.059374c-5.950311 0-11.855934 1.03125-17.534682 3.090312L117.015902 139.744645c-20.394681 7.397498-34.704989 26.774679-34.70499 48.472173v323.781463c0 72.287165 23.299368 144.584643 67.763417 214.87806 34.082802 53.875921 81.520288 106.71028 140.256832 157.045577 98.209345 84.156849 195.655565 132.70121 199.749627 134.725896a51.957797 51.957797 0 0 0 22.96593 5.352186c7.717185 0 15.485933-1.732499 22.608431-5.197498 4.094061-1.993749 101.495594-49.78186 199.797752-133.605272 58.794982-50.132485 105.520905-102.966844 139.627769-157.031827 44.446861-70.444666 66.601542-143.171831 66.601543-216.167122V188.216818c0.003437-21.731868-13.230933-41.133112-33.67374-48.503111zM164.810887 511.757656V226.768368c0-10.848747 6.648123-20.539056 16.847183-24.237805l320.759902-116.692777c2.859999-1.034687 6.142811-1.797812 9.580309-1.797812v427.961745h349.631456c0.020625 0 0.044687 0.464062 0.044688 0.759687-0.06875 93.255909-47.038736 185.679943-139.58652 275.670228-72.273415 70.279666-154.384953 120.436213-196.621503 144.096519-3.915311 2.196562-10.027184 3.231249-13.464683 3.231249V511.867656l-347.190832-0.11z" /></svg>
                            <span>Safety & Nearmiss
                            </span></a>
                        </li>
                        <li class="menu" *ngIf="roleService.isActiveSubmodule('Safety Incident Register')" routerLinkActive="active" ><a routerLink="reports/safety-incident-register"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Safety Incident Register" width="22" height="22" viewBox="0 0 24 24" fill="#000000" stroke="currentColor" stroke-width="2" class="icon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;stroke:#020202;stroke-miterlimit:10;stroke-width:1.91px;}</style></defs><path class="cls-1" d="M21.55,8.18V22.5H14.86A2.86,2.86,0,0,1,12,19.64h0A2.86,2.86,0,0,1,9.14,22.5H2.45V8.18A2.86,2.86,0,0,0,5.32,5.32V1.5H18.68V5.32A2.86,2.86,0,0,0,21.55,8.18Z"/><polygon class="cls-1" points="12 10.09 8.18 1.5 15.82 1.5 12 10.09"/><line class="cls-1" x1="12" y1="19.64" x2="12" y2="10.09"/><line class="cls-1" x1="2.45" y1="12.95" x2="9.14" y2="12.95"/><line class="cls-1" x1="2.45" y1="17.73" x2="9.14" y2="17.73"/><line class="cls-1" x1="14.86" y1="12.95" x2="21.55" y2="12.95"/><line class="cls-1" x1="14.86" y1="17.73" x2="21.55" y2="17.73"/></svg>
                            <span>Safety Incident Register</span></a>
                        </li>
                        <li class="menu" *ngIf="roleService.isActiveSubmodule('Customer Complaint')" routerLinkActive="active"><a routerLink="reports/customer-complaint"  id="mobile_btn" (click)="toggleNavMobile()">
                            <!-- <svg matTooltipPosition="right" matTooltip="Customer Complaint" width="22" height="22" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="19" cy="5" r="3" stroke="#1C274C" stroke-width="1.5"></circle> <path d="M7 14H16" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> <path d="M7 17.5H13" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> <path d="M2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12V10.5M13.5 2H12C7.28595 2 4.92893 2 3.46447 3.46447C2.49073 4.43821 2.16444 5.80655 2.0551 8" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> </g></svg> -->
                            <svg matTooltipPosition="right" matTooltip="Customer Complaint" width="22" height="22" viewBox="0 0 24 24" fill="#000000" stroke="currentColor" stroke-width="2" class="icon" xmlns="http://www.w3.org/2000/svg"><path d="M2,21h8a1,1,0,0,0,0-2H3.071A7.011,7.011,0,0,1,10,13a5.044,5.044,0,1,0-3.377-1.337A9.01,9.01,0,0,0,1,20,1,1,0,0,0,2,21ZM10,5A3,3,0,1,1,7,8,3,3,0,0,1,10,5Zm13,8.5v5a.5.5,0,0,1-.5.5h-1v2L19,19H14.5a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5h8A.5.5,0,0,1,23,13.5Z"/></svg>
                            <span>Customer Complaint</span></a>
                        </li>
                        <li class="menu" *ngIf="roleService.isActiveSubmodule('Audit Report')" routerLinkActive="active" ><a routerLink="reports/audit-report"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg   matTooltipPosition="right" matTooltip="Audit Report" fill="#000000" width="22" height="22" viewBox="0 0 1024 1024" stroke="currentColor" stroke-width="2" xmlns="http://www.w3.org/2000/svg" class="icon">
                                <path d="M296 250c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm184 144H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm-48 458H208V148h560v320c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h264c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm440-88H728v-36.6c46.3-13.8 80-56.6 80-107.4 0-61.9-50.1-112-112-112s-112 50.1-112 112c0 50.7 33.7 93.6 80 107.4V764H520c-8.8 0-16 7.2-16 16v152c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V780c0-8.8-7.2-16-16-16zM646 620c0-27.6 22.4-50 50-50s50 22.4 50 50-22.4 50-50 50-50-22.4-50-50zm180 266H566v-60h260v60z"/>
                              </svg>
                            <span>Audit Report</span></a>
                        </li>
                        <li class="menu" *ngIf="roleService.isActiveSubmodule('FE Audit')"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/fe-audit"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="FE Audit" width="20" height="20" viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><defs><style>.cls-1{fill:none;stroke:#020202;stroke-miterlimit:10;stroke-width:1.82px;}</style></defs><path class="cls-1" d="M10.14,5.64h0a4.55,4.55,0,0,1,4.55,4.55V22a0,0,0,0,1,0,0H5.59a0,0,0,0,1,0,0V10.18A4.55,4.55,0,0,1,10.14,5.64Z"></path><rect class="cls-1" x="5.59" y="12.91" width="9.09" height="4.55"></rect><rect class="cls-1" x="8.32" y="2" width="3.64" height="3.64"></rect><polygon class="cls-1" points="21.95 5.64 16.5 4.73 16.5 2.91 21.95 2 21.95 5.64"></polygon><line class="cls-1" x1="11.95" y1="3.82" x2="16.5" y2="3.82"></line><polyline class="cls-1" points="8.32 3.82 3.8 3.82 1.04 7.46"></polyline></g></svg>
                            <span>FE Audit</span></a></li>
                    </ul>
                </li>
                <li class="submenu-open" >
                    <h6 class="submenu-hdr">Master</h6>
                    <ul>
                        <li class="menu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="master/reason"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Reason" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                            <!-- <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg> -->
                            <span>Reason</span></a>
                        </li>
                        <li class="menu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="master/cutoff"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Cutoff" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#313131" class="bi bi-receipt-cutoff" stroke="#313131"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"></path> <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"></path> </g></svg>
                            <span>Cutoff</span></a>
                        </li>
                        <li class="menu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="master/calculation"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Calculation" fill="#313131" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg" stroke="#313131"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <defs> <style> .cls-1 { fill: none; } </style> </defs> <rect x="19" y="13" width="6" height="2"></rect> <polygon points="13 21 11 21 11 19 9 19 9 21 7 21 7 23 9 23 9 25 11 25 11 23 13 23 13 21"></polygon> <rect x="7" y="9" width="6" height="2"></rect> <rect x="19" y="17" width="6" height="2"></rect> <path d="M27,3H5A2.0023,2.0023,0,0,0,3,5V27a2.0023,2.0023,0,0,0,2,2H27a2.0023,2.0023,0,0,0,2-2V5A2.0023,2.0023,0,0,0,27,3ZM15,5V15H5V5ZM5,17H15V27H5ZM17,27V5H27V27Z"></path> <rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" class="cls-1" width="32" height="32"></rect> </g></svg>
                            <span>Calculation</span></a>
                        </li>
                        <li class="menu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="master/customer"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Customer" viewBox="0 -0.5 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="si-glyph si-glyph-customer-support" fill="#313131" stroke="#313131"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>715</title> <defs> </defs> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g transform="translate(1.000000, 0.000000)" fill="#313131"> <g transform="translate(0.000000, 2.000000)"> <path d="M8.005,10.094 C6.563,10.094 5.098,9.238 4.243,8.105 C0.141,8.105 0.017,13.965 0.017,13.965 L15.992,13.965 C15.992,13.965 16.314,8.079 11.701,8.079 C10.847,9.226 9.447,10.094 8.005,10.094 Z" class="si-glyph-fill"> </path> <path d="M11.441,3.069 C11.441,4.755 9.902,7.979 8.002,7.979 C6.105,7.979 4.565,4.754 4.565,3.069 C4.565,1.384 6.104,0.016 8.002,0.016 C9.902,0.017 11.441,1.385 11.441,3.069 L11.441,3.069 Z" class="si-glyph-fill"> </path> </g> <path d="M12.975,3.614 C12.975,3.298 12.535,3.043 11.989,3.041 L11.989,2.39 C11.989,2.293 12.026,0.022 8.014,0.022 C4.004,0.022 4.041,2.293 4.041,2.39 L4.041,3.064 C4.031,3.064 4.023,3.061 4.014,3.061 C3.471,3.061 3.034,3.312 3.034,3.623 L3.034,6.377 C3.034,6.686 3.472,6.938 4.014,6.938 C4.556,6.938 4.996,6.686 4.996,6.377 L4.996,3.623 C4.996,3.58 4.969,3.541 4.953,3.501 L4.953,2.75 C4.953,2.681 4.665,0.967 8.014,0.967 C11.364,0.967 11.016,2.681 11.016,2.75 L11.016,3.541 C11.01,3.566 10.991,3.588 10.991,3.614 L10.991,6.431 C10.991,6.748 11.434,7.005 11.983,7.005 C11.995,7.005 12.004,7.001 12.016,7.001 L12.016,8.03 L11.032,8.03 L11.032,9 L12.985,9 L12.975,3.614 L12.975,3.614 Z" class="si-glyph-fill"> </path> </g> </g> </g></svg>
                            <span>Customer</span></a>
                        </li>
                        <li class="menu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="master/warehouse"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Warehouse" viewBox="0 0 15 15" version="1.1" id="warehouse" xmlns="http://www.w3.org/2000/svg" fill="#313131" stroke="#313131"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M13.5,5c-0.0762,0.0003-0.1514-0.0168-0.22-0.05L7.5,2L1.72,4.93C1.4632,5.0515,1.1565,4.9418,1.035,4.685
                                S1.0232,4.1215,1.28,4L7.5,0.92L13.72,4c0.2761,0.0608,0.4508,0.3339,0.39,0.61C14.0492,4.8861,13.7761,5.0608,13.5,5z M5,10H2v3h3
                                V10z M9,10H6v3h3V10z M13,10h-3v3h3V10z M11,6H8v3h3V6z M7,6H4v3h3V6z"></path> </g></svg>
                            <span>Warehouse</span></a>
                        </li>
                    </ul>
                </li>
                <li class="submenu-open" *ngIf="roleService.isActiveModule('Settings')">
                    <h6 class="submenu-hdr">Settings</h6>
                    <ul>
                        <li *ngIf="roleService.isActiveSubmodule('Users')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="setting/users"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Users" viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                            <!-- <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg> -->
                            <span>Users</span></a>
                        </li>
                        <li class="menu" *ngIf="roleService.isActiveSubmodule('Notifications')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="setting/notification"  id="mobile_btn" (click)="toggleNavMobile()">
                            <svg matTooltipPosition="right" matTooltip="Notifications" width="22" height="22" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="19" cy="5" r="3" stroke="#1C274C" stroke-width="1.5"></circle> <path d="M7 14H16" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> <path d="M7 17.5H13" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> <path d="M2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12V10.5M13.5 2H12C7.28595 2 4.92893 2 3.46447 3.46447C2.49073 4.43821 2.16444 5.80655 2.0551 8" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                            <span>Notifications</span></a>
                        </li>
                    </ul>
                </li>

                <!-- this setting menu is for user only  -->
                <li class="submenu-open mobilview" *ngIf="userRole === 'ROLE_USER' || userRole === 'ROLE_STAFF'">
                    <h6 class="submenu-hdr">Settings</h6>
                    <ul>
                        <li (click)="showChangePassDialog()" routerLink="setting/users" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="setting/users"  id="mobile_btn" (click)="toggleNavMobile()">
                            <i class="me-2" data-feather="unlock"></i><span>Change Password</span></a>
                        </li>
                    </ul>
                </li>
                <li class="submenu-open">
                    <ul>
                        <li>
                            <a (click)="logOut()" ><i matTooltip="Logout" matTooltipPosition="right" data-feather="log-out"></i><span>Logout</span> </a>
                        </li>
                        <li>
                            <a>
                                <div class="mobilview">
                                    <input type="checkbox" class="checkbox" id="checkbox1" [(ngModel)]="themeValue" (click)="toggleTheme()">
                                    <label for="checkbox1" class="checkbox-label">
                                      <i data-feather="moon" class="fas fa-moon"></i>
                                      <i data-feather="sun" class="fas fa-sun"></i>
                                      <span class="ball"></span>
                                    </label>
                                  </div>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>

<app-changePassword-modal 
	[display]="changePassDialogDisplay" 
	(confirm)="onConfirmation($event)">
  </app-changePassword-modal>