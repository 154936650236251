import { Component, ElementRef, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import * as feather from 'feather-icons';
import { ThemeService } from '../../services/theme.service';

@Component({
  // standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{
  userDetails:any;
  changePassDialogDisplay: any;
  userRole:any; 
  themeValue = false
  constructor(private auth : AuthService,private elementRef: ElementRef,public themeService: ThemeService){
    const getUserDetails:any = this.auth.getUserDetails() ? this.auth.getUserDetails() : localStorage.getItem('user_details');
    this.userDetails = JSON.parse(getUserDetails)
    // console.log("=====xuserDetails+++++++++++",this.userDetails)
  }

  ngOnInit(): void {
    const theme = localStorage.getItem('darkTheme')
    this.themeValue = theme === 'true' ? true : false
    this.userRole = this.userDetails.role
  }
  toggleNav(){
    const toggleBtn:any = document.getElementById('toggle_btn');

    const body = document.body;
    if (body.classList.contains('mini-sidebar')) {
      body.classList.remove('mini-sidebar');
      // toggleBtn.classList.add('active');
      // localStorage.setItem(screenModeNightTokenState, 'night');
      setTimeout(() => {
        body.classList.remove('mini-sidebar');
        document.querySelector(".header-left")?.classList.add("active");
      }, 100);
    }else {
      body.classList.add('mini-sidebar');
      toggleBtn.classList.remove('active');
      // localStorage.removeItem(screenModeNightTokenState);
      setTimeout(() => {
        body.classList.add('mini-sidebar');
        document.querySelector(".header-left")?.classList.remove("active");
      }, 100);
  } 
} 
 
toggleNavMobile(){
  const toggleBtn:any = document.getElementById('mobile_btn');

  const body = document.body;
  // const wrapper = document.querySelector(".main-wrapper");
  
  if (document.querySelector(".slide-nav")) {
    document.querySelector(".main-wrapper")?.classList.remove("slide-nav");
    
    body.classList.remove('menu-opened');
    // toggleBtn.classList.add('active');
    // localStorage.setItem(screenModeNightTokenState, 'night');
    setTimeout(() => {
      body.classList.remove('mini-sidebar');
      document.querySelector(".header-left")?.classList.add("active");
    }, 100);
  }else {
    document.querySelector(".main-wrapper")?.classList.add("slide-nav");

    body.classList.add('menu-opened');
    toggleBtn.classList.remove('active');
    // localStorage.removeItem(screenModeNightTokenState);
    setTimeout(() => {
      body.classList.add('mini-sidebar');
      document.querySelector(".header-left")?.classList.remove("active");
    }, 100);
} 
} 

ngAfterViewInit() {
  feather.replace();
}

logOut(){
  localStorage.setItem('darkTheme', 'false');
  document.body.classList.remove('dark-theme');
  document.body.classList.add('light-theme');
  this.auth.logOut()
}

onConfirmation(choice: boolean) {
  this.elementRef.nativeElement.ownerDocument.body.style.pointerEvents = '';
  if (choice) {
    this.changePassDialogDisplay = false;
    // Proceed with the update
    console.log('Updating...');
  } else {
    this.changePassDialogDisplay = false;
    // Cancel the update
    console.log('Update cancelled.');
  }
}
showChangePassDialog() {
  this.elementRef.nativeElement.ownerDocument.body.style.pointerEvents = 'none';
  // this.changePassDialogDisplay = true;
  this.changePassDialogDisplay = true
}
toggleTheme() {
  this.themeService.toggleTheme();
}
}
