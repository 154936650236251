<div class="page-wrapper" [ngClass]="[theme === 'dark'? 'dark' : '']">
    <div class="content">
        <div class="order-details">
            <div class="content p-0">
                <div class="page-header">
                    <div class="page-title d-flex justify-content-between w-100">
                        <h4>Audit Report</h4>
                        <div class="pagination">
                            <button *ngIf="!showExcel" class="Printall-label btn btn-submit"
                                (click)="fetchDataAndCreateExcel('excel')"
                                [disabled]="!filterForm.controls.rangeDates.value">
                                <svg [ngStyle]="{fill: !filterForm.controls.rangeDates.value ? 'grey' : ''}"
                                    viewBox="0 0 24 24" width="18" height="18" stroke="#fff" stroke-width="2"
                                    fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                    <polyline points="6 9 6 2 18 2 18 9"></polyline>
                                    <path
                                        d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2">
                                    </path>
                                    <rect x="6" y="14" width="12" height="8"></rect>
                                </svg> Excel
                            </button>
                            <p-progressBar *ngIf="showExcel && !showReason && progress>0" [value]="progress"
                                [style]="{width:'150px',height: '35px'}" />

                            <!-- <button *ngIf="!showReason" class="Printall-label btn btn-submit ms-3" (click)="fetchDataAndCreateExcel('reason')"  [disabled]="!FilterForm.controls.rangeDates.value">
                                <svg [ngStyle]="{fill: !FilterForm.controls.rangeDates.value ? 'grey' : ''}" viewBox="0 0 24 24" width="18" height="18" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>  Reason Excel
                            </button>	 -->
                            <p-progressBar *ngIf="!showExcel && showReason && progress>0" [value]="progress"
                                [style]="{width:'150px',height: '35px',margin: '0 0 0 15px'}" />
                        </div>
                    </div>
                </div>


                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top position-relative">
                            <form [formGroup]="filterForm">
                                <div class="search-set justify-content-between w-100">
                                    <div class="search-path page-header ">
                                        <a class="btn btn-cancel" (click)="reset()">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                            Reset
                                        </a>
                                        <a class="btn btn-added ms-3" (click)="op.toggle($event)" icon="pi pi-image"
                                            label="Show">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                                                </path>
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                                                </path>
                                            </svg>
                                            Filter Data</a>
                                    </div>
                                    <div class="filterCard flex justify-content-center" style="width: 35vw;">
                                        <p-overlayPanel #op [showCloseIcon]="true" [dismissable]='false'>
                                            <ng-template pTemplate="content">
                                                <div class="card">
                                                    <h4 class="filterHeader">Filter Data</h4>
                                                    <div class="row">

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Warehouse</label>
                                                                <p-multiSelect [options]="warehouseList"
                                                                    formControlName="warehouse"
                                                                    placeholder="Select a Warehouse"
                                                                    optionLabel="plantName"
                                                                    (onChange)="selectWarehouse($event)"></p-multiSelect>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Customer</label>
                                                                <p-dropdown formControlName="customer"
                                                                    [options]="customerList"
                                                                    placeholder="Select Customer"
                                                                    optionLabel="customerName"></p-dropdown>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Date Range</label>
                                                                <p-calendar formControlName="rangeDates"
                                                                    selectionMode="range"
                                                                    placeholder="Start Date - End Date"
                                                                    (onSelect)="selectRange($event)"></p-calendar>
                                                                <!-- <p-dropdown formControlName="selectedCategoryObni" [options]="categoryObni" optionLabel="name" placeholder="Select a Warehouse"></p-dropdown> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Audit Type</label>
                                                                <p-dropdown formControlName="auditType"
                                                                    [options]="auditTypeOptions"
                                                                    placeholder="Select Audit Type">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="search-path page-header ">
                                                        <a class="btn btn-primary" id="filter_search"
                                                            (click)="op.toggle(false);applyFilter()">
                                                            <svg viewBox="0 0 24 24" width="18" height="18"
                                                                stroke="currentColor" stroke-width="2" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="css-i6dzq1">
                                                                <circle cx="11" cy="11" r="8"></circle>
                                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                            </svg>
                                                            Apply Filter
                                                        </a>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-overlayPanel>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="table-responsive">
                            <table class="table order-data">
                                <thead class="table-head">
                                    <tr>
                                        <!-- Conditional rendering of columns based on audit type -->
                                        <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway' || 
                                        filterForm.controls.auditType.value === 'Picking'">
                                            <th *ngFor="let column of putawayPickingCycleCountColumns">{{ column }}</th>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">
                                            <th *ngFor="let column of cycleCountColumns">{{ column }}</th>
                                        </ng-container>

                                        <ng-container *ngIf="filterForm.controls.auditType.value === 'Empty Bin'">
                                            <th *ngFor="let column of emptyBinColumns">{{ column }}</th>
                                        </ng-container>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of auditData">
                                        <ng-container
                                            *ngIf="filterForm.controls.auditType.value === 'Putaway' || filterForm.controls.auditType.value === 'Picking' || filterForm.controls.auditType.value === 'Cycle Count'">

                                            <td> <a [routerLink]="['/setting/kpi']"
                                                    [queryParams]="{recordDate: row.recordDate, customerId: row.customerMaster?.id, warehouseId: row.warehouseInfo?.id, auditType: filterForm.controls.auditType.value}"
                                                    class="me-3 d-flex justify-content-center">
                                                    <img matTooltip="Edit" src="assets/img/icons/edit.svg" alt="img">
                                                </a></td>
                                            <td>{{row.recordDate}}</td>
                                            <td>{{row?.customerMaster?.customerName}}</td>
                                            <td>{{row?.warehouseInfo?.plantName}}</td>
                                            <td>{{filterForm.controls.auditType.value}}</td>
                                            <!-- Total Completed(Bin) -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">
                                                    <!--  <span>Putaway: </span> -->
                                                    <span>{{ row?.putawayAudit?.completedActionBin ?
                                                        row?.putawayAudit?.completedActionBin : 0}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.completedActionBin ?
                                                        row?.pickListAudit?.completedActionBin : 0 }}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.plannedBinCnt ?
                                                        row?.cycleAudit?.plannedBinCnt : 0 }}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Total Completed(Qty) -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.completedActionQty ?
                                                        row?.putawayAudit?.completedActionQty : 0}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.completedActionQty ?
                                                        row?.pickListAudit?.completedActionQty : 0 }}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.plannedQtyCnt ?
                                                        row?.cycleAudit?.plannedQtyCnt : 0 }}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Attempt for Audit(Bin) -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.attemptBinCnt ?
                                                        row?.putawayAudit?.attemptBinCnt : 0}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.attemptBinCnt ?
                                                        row?.pickListAudit?.attemptBinCnt : 0 }}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.attemptBinCnt ?
                                                        row?.cycleAudit?.attemptBinCnt : 0 }}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Attempt for Audit(Qty)  -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.attemptQtyCnt ?
                                                        row?.putawayAudit?.attemptQtyCnt : 0}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.attemptQtyCnt ?
                                                        row?.pickListAudit?.attemptQtyCnt : 0 }}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.attemptQtyCnt ?
                                                        row?.cycleAudit?.attemptQtyCnt : 0 }}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Correct Bin -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.correctBin ?
                                                        row?.putawayAudit?.correctBin : 0}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.correctBin ?
                                                        row?.pickListAudit?.correctBin : 0 }}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.correctBin ? row?.cycleAudit?.correctBin :
                                                        0 }}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Correct Qty -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.correctQty ?
                                                        row?.putawayAudit?.correctQty : 0}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.correctQty ?
                                                        row?.pickListAudit?.correctQty : 0 }}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.correctQty ? row?.cycleAudit?.correctQty :
                                                        0 }}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Discrepancy(Bin) -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.discrepancyBin ?
                                                        row?.putawayAudit?.discrepancyBin : 0}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.discrepancyBin ?
                                                        row?.pickListAudit?.discrepancyBin : 0 }}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.discrepancyBin ?
                                                        row?.cycleAudit?.discrepancyBin : 0 }}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Discrepancy(Qty) -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.discrepancyQty ?
                                                        row?.putawayAudit?.discrepancyQty : 0}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.discrepancyQty ?
                                                        row?.pickListAudit?.discrepancyQty : 0 }}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.discrepancyQty ?
                                                        row?.cycleAudit?.discrepancyQty : 0 }}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Bin Accuracy % -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.binAccuracyPercentage ?
                                                        row?.putawayAudit?.binAccuracyPercentage : 0 | number:'1.2-2'}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.binAccuracyPercentage ?
                                                        row?.pickListAudit?.binAccuracyPercentage : 0 | number:'1.2-2'}}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.binAccuracyPercentage ?
                                                        row?.cycleAudit?.binAccuracyPercentage : 0 | number:'1.2-2'}}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Stock Accuracy % -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.stockAccuracyPercentage ?
                                                        row?.putawayAudit?.stockAccuracyPercentage : 0 | number:'1.2-2'}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.stockAccuracyPercentage ?
                                                        row?.pickListAudit?.stockAccuracyPercentage : 0 | number:'1.2-2'}}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.stockAccuracyPercentage ?
                                                        row?.cycleAudit?.stockAccuracyPercentage : 0 | number:'1.2-2'}}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Attempt Bin % -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.attemptBinPercentage ?
                                                        row?.putawayAudit?.attemptBinPercentage : 0 | number:'1.2-2'}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.attemptBinPercentage ?
                                                        row?.pickListAudit?.attemptBinPercentage : 0 | number:'1.2-2'}}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.attemptBinPercentage ?
                                                        row?.cycleAudit?.attemptBinPercentage : 0 | number:'1.2-2'}}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Attempt Qty % -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.attemptQtyPercentage ?
                                                        row?.putawayAudit?.attemptQtyPercentage : 0 | number:'1.2-2'}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.attemptQtyPercentage ?
                                                        row?.pickListAudit?.attemptQtyPercentage : 0 | number:'1.2-2'}}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.attemptQtyPercentage ?
                                                        row?.cycleAudit?.attemptQtyPercentage : 0 | number:'1.2-2'}}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Remarks -->
                                            <td>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Putaway'">

                                                    <span>{{ row?.putawayAudit?.remarks ? row?.putawayAudit?.remarks :
                                                        'NA'}}</span>

                                                </ng-container>
                                                <ng-container *ngIf="filterForm.controls.auditType.value === 'Picking'">

                                                    <span>{{ row?.pickListAudit?.remarks ? row?.pickListAudit?.remarks :
                                                        'NA' }}</span>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Cycle Count'">

                                                    <span>{{ row?.cycleAudit?.remarks ? row?.cycleAudit?.remarks : 'NA'
                                                        }}</span>
                                                </ng-container>
                                            </td>
                                        </ng-container>
                                        <ng-container *ngIf="filterForm.controls.auditType.value === 'Empty Bin'">
                                            <!-- Empty Bin case -->
                                            <td> <a [routerLink]="['/setting/kpi']"
                                                    [queryParams]="{recordDate: row.recordDate, customerId: row.customerMaster?.id, warehouseId: row.warehouseInfo?.id, auditType: filterForm.controls.auditType.value}"
                                                    class="me-3 d-flex justify-content-center">
                                                    <img matTooltip="Edit" src="assets/img/icons/edit.svg" alt="img">
                                                </a></td>
                                            <td>{{row.recordDate}}</td>
                                            <td>{{row?.customerMaster?.customerName}}</td>
                                            <td>{{row?.warehouseInfo?.plantName}}</td>
                                            <td>{{filterForm.controls.auditType.value}}</td>
                                            <!-- Total Empty locations(Bin) -->

                                            <td>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Empty Bin'">
                                                    <span>{{row?.emptyAudit?.completedActionBin ?
                                                        row?.emptyAudit?.completedActionBin : 0}}</span>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Empty Bin'">
                                                    <span>{{row?.emptyAudit?.attemptBinCnt ?
                                                        row?.emptyAudit?.attemptBinCnt : 0}}</span>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Empty Bin'">
                                                    <span>{{row?.emptyAudit?.correctBin ? row?.emptyAudit?.correctBin :
                                                        0}}</span>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Empty Bin'">
                                                    <span>{{row?.emptyAudit?.discrepancyBin ?
                                                        row?.emptyAudit?.discrepancyBin : 0}}</span>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Empty Bin'">
                                                    <span>{{row?.emptyAudit?.discrepancyQty ?
                                                        row?.emptyAudit?.discrepancyQty : 0}}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Bin Accuracy % -->
                                            <td>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Empty Bin'">
                                                    <span>{{row?.emptyAudit?.binAccuracyPercentage ?
                                                        row?.emptyAudit?.binAccuracyPercentage : 0 | number:'1.2-2'}}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Attempt Bin % -->
                                            <td>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Empty Bin'">
                                                    <span>{{row?.emptyAudit?.attemptBinPercentage ?
                                                        row?.emptyAudit?.attemptBinPercentage : 0 | number:'1.2-2'}}</span>
                                                </ng-container>
                                            </td>
                                            <!-- Remarks -->
                                            <td>
                                                <ng-container
                                                    *ngIf="filterForm.controls.auditType.value === 'Empty Bin'">
                                                    <span>{{row?.emptyAudit?.remarks ? row?.emptyAudit?.remarks :
                                                        'NA'}}</span>
                                                </ng-container>
                                            </td>
                                        </ng-container>

                                        <!-- </ng-container> -->
                                    </tr>
                                    <tr *ngIf="auditData?.length == 0">
                                        <div class="no-order pt-4 pb-4">
                                            <p class="margin-0">No data found</p>
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="pagination search-set justify-content-end flex-end w-100">
                    <mat-paginator class="demo-paginator" (page)="pageNavigations($event)" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]" aria-label="Select page">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</div>