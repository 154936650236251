import { Component } from '@angular/core';

@Component({
  selector: 'app-fire-extinguisher',
  templateUrl: './fire-extinguisher.component.html',
  styleUrls: ['./fire-extinguisher.component.css']
})
export class FireExtinguisherComponent {

}
