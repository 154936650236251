import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatForExcelNearmiss'
})
export class FormatForExcelNearmissPipe implements PipeTransform {

  transform(value: any): string {
    if (!value || !Array.isArray(value)) {
      return '';
    }

    return value.map((item, index) => {
      return `${item.dropdownMaster.value}: ${item.userValue}`;
    }).join(', ');
  }

}
