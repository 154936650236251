import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { UpdateRoutingModule } from './update-routing.module';
import { PmsInboundComponent } from './components/pms-inbound/pms-inbound.component';
import { PmsOutboundComponent } from './components/pms-outbound/pms-outbound.component';
import { PmsKpiTableComponent } from './components/pms-kpi-table/pms-kpi-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppMaterialModule } from '../_material/material/material.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { PickFailKpiComponent } from './components/pick-fail-kpi/pick-fail-kpi.component';
import { AddUpdatePmsKpiComponent } from './constants/add-update-pms-kpi/add-update-pms-kpi.component';

@NgModule({
  declarations: [
    PmsInboundComponent,
    PmsOutboundComponent,
    PmsKpiTableComponent,
    PickFailKpiComponent,
    AddUpdatePmsKpiComponent,
  ],
  imports: [
    CommonModule,MultiSelectModule,
    UpdateRoutingModule,
    FormsModule,DatePipe, SharedModule, AppMaterialModule, ReactiveFormsModule,OverlayPanelModule, DropdownModule
  ]
})
export class UpdateModule { }
