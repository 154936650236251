import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { ImportOrderComponent } from './orders/container/import-order/import-order.component';
import { auth2Guard } from './_guard/auth2.guard';
import { authGuard } from './_guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: '',
    // canLoad: [auth2Guard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [authGuard],
    children: [
      // {
      //   path: 'import-order',
      //   component: ImportOrderComponent,
      //   // canActivate: [AuthGuard],
      //   data: { title: 'Opollo WMS' }
      // },
      {
        path: 'order',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'grn-report',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./grn-Report/grn-report.module').then(m => m.GrnReportModule)
      },
      {
        path: 'order-line',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./order-line/order-line.module').then(m => m.OrderLineModule)
      },
      {
        path: 'calender',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./calender/calender.module').then(m => m.CalenderModule)
      },
      {
        path: 'dashboard',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'setting',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'fe-audit',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./fe-audit/fe-audit.module').then(m => m.FeAuditModule)
      },
      {
        path: 'reports',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./reports/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'master',
        loadChildren: () => import('./master/master-routing.module').then(m => m.MasterRoutingModule)
      },
      {
        path: 'update',
        loadChildren: () => import('./update/update-routing.module').then(m => m.UpdateRoutingModule)
      },
      

    ]
  },
  { path: '**', redirectTo: '' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
