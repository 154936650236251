export class Configuration {
  public CONFIG_URL = {
    
    GET_ORDER_BY_ID:'order-service/order/getOrder?orderNumber=',
    GET_CLIENTS_BY_ID:'client-service/info/getClientById?clientId=',
    GET_TRACKING_STATUS_BY_ID:'order-service/order/createLabel?orderNumber=',
    MERGE_LABELS:'/carrier-service/labels/mergeLabels',
    PENDING_MERGE_LABELS:'order-service/order/createMultiLabel?orderNumber=',
    GET_TRACKING_ORDER:'order-service/order/getOrderTrackingStatus?orderNumber=',
    GET_RETURN_REASON:'order-service/master/getReturnsReasonMaster',
    BULK_ORDER_UPLOAD:'utils-service/uploads/uploadOrders?Id=1',
    CREATE_ORDER_RETURN:'order-service/order/createReturnOrder',
    ORDER_SUMMERY:'order-service/dashboard/orderSummary?accountId=1&from=01-03-2024&to=31-03-2024',
    GET_MULTI_CLIENTS_BY_ID:'client-service/info/getMultiClientById',
    GET_MULTI_WAREHOUSE_BY_ID:'warehouse-service/info/getMultiWarehouseById?warehouseList=',
    GET_FIELD_MAPPING_DETAILS:'gr/getFieldMappingDetails',
    ADD_FILE_FIELD_MAPPING_DETAILS:'gr/addFileFieldMappingDetails',

    GET_ALL_ABSENTISM:'kpi/getAllAbsentism',
    GET_SAFETY_AND_NEARMISS: 'safety/getSafetyAndNearmiss',
    GET_SAFETY_INCIDENT_REGISTER:'safety/getIncident',
    GET_CUSTOMER_COMPLAINT:'customerComplain/getCustomerComplaint',
    GET_AUDIT_REPORT:'audit/getAudit',

    USER_LOGIN: 'auth-service/auth/login',
    SIGNUP: 'auth-service/auth/login',
    ROLE_MAPING:'auth-service/role/getRoleMapping',
    LOGIN: 'login',
    GET_ORDER_UPDATE_LIST: 'orders/getOrderDetails',
    GET_CAL_DETAILS: 'calendar/getDetails',
    GET_GRN_DETAILS: 'grn/getGrnDetails',
    GET_ORDERLINE_DETAILS: 'orderLines/getOrderLineDetails',
    GET_REASON: 'master/getReasonInfo',
    GET_WAREHOUSE: 'master/getWarehouseList',
    GET_CUSTOMER_LIST: 'customer/getCustomer',
    CALENDAR: 'calendar',
    ORDERS: 'orders',
    GRN: 'grn',
    UPLOAD: 'uploads',
    USER_LIST:'auth-service/auth/getUsers',
    CREATE_USER:'auth-service/auth/createUser',
    UPDATE_USER:'auth-service/auth/updateUser',
    CHECK_USER:'auth-service/auth/checkUserName?userName=',
    CHANGEPASSWORD: 'auth-service/auth/updatePassword',
    email: 'email',
    kpi: 'kpi',
    dropdown: 'dropdown',
    fe: 'fe',
    GET_GR_DETAIL: 'gr/getGrDetails',
    GET_DEFAULT_LOGIC_DETAILS: 'calculation/getCalculationDetails',
    UPDATE_CALCULATION_DETAILS: 'calculation/updateGrCalcualtionData',
    RESET_CALCULATION_LOGIC: 'calculation/resetCalculationLogic',
    ADD_CALCULATION_LOGIC: 'calculation/addGrCalcualtionData',
    GET_MAPPING_DETAILS: 'gr/getMappingDetails',

    GET_PMS_INBOUND_DETAILS: 'pmsInbound/getPmsInboundDetails',
    GET_PMS_OUTBOUND_DETAILS: 'pmsOutbound/getPmsOutboundDetails',
    GET_PMS_KPI_DETAILS: 'pmsKpi/getPmsKpiDetails',
    GET_PICK_FAIL_DETAILS: 'pickFail/getPickFailDetails',

    GET_REASON_INFO: 'reason/getReasonDetails',
    GET_CUTOFF_DETAILS: 'cutoff/getCutoffDetails',

    DELETE_PMS_OUTBOUND_DETAILS: 'pmsOutbound/deletePmsOutboundDetails',
    DELETE_PMS_INBOUND_DETAILS: 'pmsInbound/deletePmsInboundDetails',
    DELETE_PICK_FAIL_DETAILS: 'pickFail/deletePickFailDetails',
    DELETE_PMS_KPI_DETAILS: 'pmsKpi/deletePmsKpiDetails',
    ADD_UPDATE_PMS_KPI: 'pmsKpi/addUpdatePmsKpiDetails',
    UPDATE_REASON: 'reason/updateReasonData'
  };
}