<div class="page-wrapper" [ngClass]="[theme === 'dark'? 'dark' : '']">
    <div class="content">
        <div class="order-details">
            <div class="content p-0">
                <div class="page-header">
                    <div class="page-title d-flex justify-content-between w-100">
                        <h4>Safety & Nearmiss</h4>
                        <div class="pagination">
                            <button *ngIf="!showExcel" class="Printall-label btn btn-submit" (click)="fetchDataAndCreateExcel('excel')"   [disabled]="!filterForm.controls.rangeDates.value">
                                <svg [ngStyle]="{fill: !filterForm.controls.rangeDates.value ? 'grey' : ''}" viewBox="0 0 24 24" width="18" height="18" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg> Excel
                            </button>
                            <p-progressBar *ngIf="showExcel && progress>0" [value]="progress" [style]="{width:'150px',height: '35px'}"/>
                            
                            <!-- <button *ngIf="!showReason" class="Printall-label btn btn-submit ms-3" (click)="fetchDataAndCreateExcel('reason')"  [disabled]="!FilterForm.controls.rangeDates.value">
                                <svg [ngStyle]="{fill: !FilterForm.controls.rangeDates.value ? 'grey' : ''}" viewBox="0 0 24 24" width="18" height="18" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>  Reason Excel
                            </button>	 -->
                            <p-progressBar *ngIf="!showExcel && showReason && progress>0" [value]="progress" [style]="{width:'150px',height: '35px',margin: '0 0 0 15px'}"/>
                        </div>
                    </div>
                </div>


                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top position-relative">
                            <form [formGroup]="filterForm">
                                <div class="search-set justify-content-between w-100">
                                    <div class="search-path page-header ">
                                        <a class="btn btn-cancel" (click)="reset()">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                            Reset
                                        </a>
                                        <a class="btn btn-added ms-3" (click)="op.toggle($event)" icon="pi pi-image"
                                            label="Show">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                                                </path>
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                                                </path>
                                            </svg>
                                            Filter Data</a>
                                    </div>
                                    <div class="filterCard flex justify-content-center" style="width: 35vw;">
                                        <p-overlayPanel #op [showCloseIcon]="true" [dismissable]='false'>
                                            <ng-template pTemplate="content">
                                                <div class="card">
                                                    <h4 class="filterHeader">Filter Data</h4>
                                                    <div class="row">

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Warehouse</label>
                                                                <p-multiSelect [options]="warehouseList"
                                                                    formControlName="warehouse"
                                                                    placeholder="Select a Warehouse"
                                                                    optionLabel="plantName"
                                                                    (onChange)="selectWarehouse($event)"></p-multiSelect>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Customer</label>
                                                                <p-dropdown formControlName="customer" [options]="customerList"
                                                                    placeholder="Select Customer"
                                                                    optionLabel="customerName" ></p-dropdown>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Date Range</label>
                                                                <p-calendar formControlName="rangeDates"
                                                                    selectionMode="range"
                                                                    placeholder="Start Date - End Date"
                                                                    (onSelect)="selectRange($event)"></p-calendar>
                                                                <!-- <p-dropdown formControlName="selectedCategoryObni" [options]="categoryObni" optionLabel="name" placeholder="Select a Warehouse"></p-dropdown> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="search-path page-header ">
                                                        <a class="btn btn-primary" id="filter_search"
                                                            (click)="op.toggle(false);applyFilter()">
                                                            <svg viewBox="0 0 24 24" width="18" height="18"
                                                                stroke="currentColor" stroke-width="2" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="css-i6dzq1">
                                                                <circle cx="11" cy="11" r="8"></circle>
                                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                            </svg>
                                                            Apply Filter
                                                        </a>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-overlayPanel>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="table-responsive">
                            <table class="table order-data">
                                <thead class="table-head">
                                    <tr>
                                        <ng-container>
                                            <th>Action</th>
                                            <th>Date</th>
                                            <th>Customer Name</th>
                                            <th>Warehouse Name</th>
                                            <th>Safety committee<br> meeting(1/0)</th>
                                            <th>Employee Participated<br> in Safety meeting</th>
                                            <th>Safety committee<br> meeting Hours</th>
                                            <th>HSE Training(1/0)</th>
                                            <th>Employee Participated<br> in HSE meeting</th>
                                            <th>HSE Traning<br> meeting Hours</th>
                                            <th>Total <br> Unsafe Condition</th>
                                            <th class="col-width">Unsafe Condition</th>
                                            <th >Total <br>Unsafe Act</th>
                                            <th class="col-width">Unsafe Act</th>
                                            <th> Total <br>Nearmess</th>
                                            <th class="col-width">Near-miss</th>
                                            <!-- <th>Safety</th> -->
                                        </ng-container>
                                    </tr>
                                    <!-- <tr>
                                        <ng-container
                                            *ngFor="let reason of absentismData[0]?.uninformedLeaveReasonList">
                                            <th>{{ reason.dropdownMaster.value }}</th>
                                        </ng-container>
                                    </tr> -->
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of safetyData">
                                        <td><a [routerLink]="['/setting/kpi']"
                                            [queryParams]="{recordDate: row.recordDate, customerId: row.customerMaster?.id, warehouseId: row.warehouseInfo?.id}" class="me-3 d-flex justify-content-center" >
                                            <img matTooltip="Edit" src="assets/img/icons/edit.svg" alt="img">
                                          </a></td>
                                          <td>{{row.recordDate}}</td>
                                        <td>{{row?.customerMaster?.customerName}}</td>
                                        <td>{{row?.warehouseInfo?.plantName}}</td>
                                        <td>{{row?.safetyCommitteeMeeting}}</td>
                                        <td>{{row?.employeeParticipatedInSafetyMeeting}}</td>
                                        <td>{{row?.safetyCommitteeMeetingHours}}</td>
                                        <td>{{row?.hseTraining}}</td>
                                        <td>{{row?.employeeParticipatedInHseMeeting}}</td>
                                        <td>{{row?.hseTrainingMeetingHours}}</td>
                                        <td>{{row?.unsafeCondition}}</td>
                                        <td class="col-width"><pre [innerHTML]="row?.unsafeConditionList | formatNearmess"></pre></td>
                                        <td>{{row?.unsafeAct}}</td>
                                        <td class="col-width"><pre [innerHTML]="row?.unsafeActList | formatNearmess"></pre></td>
                                        <td>{{row?.nearmiss}}</td>
                                        <td class="col-width"><pre [innerHTML]="row?.nearmissList | formatNearmess"></pre></td>
                                        <!-- <td></td> -->
                                        
                                    </tr>
                                    <tr *ngIf="safetyData?.length == 0">
                                        <div class="no-order pt-4 pb-4">
                                            <p class="margin-0">No data found</p>
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="pagination search-set justify-content-end flex-end w-100">
                    <mat-paginator class="demo-paginator" (page)="pageNavigations($event)" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]" aria-label="Select page">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</div>