<div class="">
    <div class="content">
      <div class="order-details">
        <div class=" p-0">
          <div class="page-header">
            <div class="page-title" style="margin-left: -10px;">
              <h4>Add Calculation</h4>
            </div>
            <div class="close">
              <a  mat-dialog-close="" >
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="#333" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
              </a>
            </div>
            </div>

            <div class="card border-none custom-card">
                <div class="card-body p-0">
                    <form [formGroup]="addCalculationForm" (ngSubmit)="onSubmit(addCalculationForm)">
                        <div class="row mt-2">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Report Name</label>
                                    <p-dropdown formControlName="fileType"
                                        [options]="fileType" placeholder="Select Type">
                                    </p-dropdown>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>Select Customer</label>
                                    <p-dropdown formControlName="customerName"
                                        [options]="customerList"
                                        placeholder="Select Customer"
                                        optionValue="customerName"
                                        optionLabel="customerName"></p-dropdown>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>Column Name</label>
                                    <p-dropdown formControlName="tblColumnName"
                                        [options]="columnNameList"
                                        placeholder="Select Column Name"
                                        optionValue="columnName"
                                        optionLabel="columnName"></p-dropdown>
                                    <!-- <input formControlName="tblColumnName" type="text" > -->
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>Default Formula</label>
                                    <textarea type="text" rows="2" formControlName="defaultFormula"></textarea>
                                </div>
                            </div> -->
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label class="col-md-12">
                                        <span class="col-md-4">Add Formula</span>
                                        
                                    </label>
                                    <textarea type="text" rows="1" formControlName="formula"></textarea>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row mt-2 mb-3">
                            <div class="col-lg-12">
                                <button class="btn btn-submit me-2" type="submit">Submit
                                    <div class="loader"> 
                                        <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                                    </div>
                                </button>
                                  <a href="javascript:void(0);" class="btn btn-cancel"  (click)="closeDialog()">Cancel</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>